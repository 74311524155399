<template>
    <q-dialog
        ref="dialog"
        :maximized="maximizedToggle"
        persistent
    >
        <q-card class="req-dialog-card">
            <div
                v-if="requestSaving || requestIsLoading"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <!-- status and title --------------------------------------------->
            <div class="req-dialog-title">
                <q-space />
                <q-btn
                    dense
                    flat
                    :icon="matMinimize"
                    :disable="!maximizedToggle"
                    @click="maximizedToggle = false"
                />
                <q-btn
                    dense
                    flat
                    :icon="matCropSquare"
                    :disable="maximizedToggle"
                    @click="maximizedToggle = true"
                />
                <q-btn
                    dense
                    flat
                    :icon="matClose"
                    :disable="maximizedToggle"
                    @click="closeRequest"
                />
            </div>
            <div class="req-dialog-status">
                <div class="cell">
                    № заявки
                </div>
                <div class="cell cell--data">
                    {{ request.number }}
                </div>
                <div class="cell">
                    Дата
                </div>
                <div class="cell cell--data">
                    {{ $moment(request.request_date_add).format("DD.MM.YYYY") }}
                </div>
                <div class="cell">
                    Тип заявки
                </div>
                <div class="cell cell--data">
                    {{ reqTypeString }}
                </div>
                <div class="cell">
                    Статус
                </div>
                <div class="cell cell--data">
                    {{ reqStatusString }}
                </div>
                <div class="cell">
                    Исполнитель
                </div>
                <div class="cell cell--data">
                    {{ requestEngineers[0] ? getUserFIO(requestEngineers[0]) : "" }}
                </div>
            </div>

            <!-- tabs --------------------------------------------------------->
            <q-tabs
                v-model="tabCurrent"
                align="left"
                class="bg-primary text-white shadow-2"
                activeBgColor="accent"
                :breakpoint="0"
            >
                <q-tab
                    name="info"
                    :label="request.id ? 'Заявка' : 'Новая заявка'"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.FILIAL"
                    name="tab2"
                    label="Движение"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.FILIAL"
                    name="tab3"
                    label="Документы"
                />
                <q-tab
                    v-if="request.id && request.request_performer_kind == PERFORM_KIND.SENIOR"
                    name="hotline"
                    label="Техподдержка ГЛ"
                />
                <q-tab
                    v-if="request.id"
                    name="comments"
                    label="Чат по заявке"
                />
                <q-tab
                    v-if="request.id"
                    name="tab4"
                    label="История"
                />
            </q-tabs>

            <div :class="maximizedToggle ? 'req-panel-max' : 'req-panel-min'">
                <!-- tab panels ----------------------------------------------->
                <q-tab-panels
                    v-model="tabCurrent"
                    animated
                    keepAlive
                >
                    <q-tab-panel name="info">
                        <!-- common info -------------------------------------->
                        <DialogEditRequestCommon
                            :validations="validations"
                            :canBeEdited="!!canBeEdited"
                            @setRequestSaving="(v) => requestSaving = v"
                        />
                        <!-- files -------------------------------------------->
                        <Documents
                            fileSection="request"
                            :filesList="filesList"
                            :addDocToList="addDoc"
                            :deleteDoc="delDoc"
                            :readonly="!canBeEdited"
                        />
                    </q-tab-panel>
                    <!-- moving ----------------------------------------------->
                    <q-tab-panel name="tab2">
                        <DialogEditRequestMoving
                            :validations="validations"
                            :canBeEdited="!!canBeEdited"
                            :requestCompleted="requestCompleted"
                        />
                    </q-tab-panel>
                    <!-- documents -------------------------------------------->
                    <q-tab-panel name="tab3">
                        <DialogEditRequestDocuments :requestCompleted="requestCompleted" />
                    </q-tab-panel>
                    <!-- hotline ---------------------------------------------->
                    <q-tab-panel name="hotline">
                        <DialogEditRequestHotline />
                    </q-tab-panel>
                    <!-- comments --------------------------------------------->
                    <q-tab-panel name="comments">
                        <DialogEditRequestComments :requestId="request.id" />
                    </q-tab-panel>
                    <!-- history ---------------------------------------------->
                    <q-tab-panel name="tab4">
                        <DialogEditRequestHistory :history="requestHistory" />
                    </q-tab-panel>
                </q-tab-panels>
            </div>

            <q-separator />

            <q-card-actions>
                <q-btn
                    v-if="request.id &&
                        request.request_kind_id !== REQUEST_KINDS_VALUES.REVOKE &&
                        canBeEdited &&
                        !requestCompleted"
                    flat
                    label="Отозвать заявку"
                    color="primary"
                    :icon="matRotateLeft"
                    @click="recalRequest"
                />
                <q-space />
                <template v-if="!request.id">
                    <q-btn
                        v-if="canBeEdited"
                        flat
                        label="Создать"
                        color="primary"
                        :icon="matSave"
                        @click="saveRequest(true)"
                    />
                </template>
                <template v-else>
                    <q-btn
                        v-if="canBeEdited"
                        flat
                        label="Сохранить"
                        color="primary"
                        :icon="matSave"
                        @click="saveRequest(false)"
                    />
                    <q-btn
                        v-if="canBeEdited"
                        flat
                        label="Закрыть"
                        color="primary"
                        :icon="matClose"
                        @click="saveRequest(true)"
                    >
                        <q-tooltip
                            :delay="300"
                            anchor="top right"
                            self="bottom middle"
                            class="bg-amber text-body2 text-black shadow-4"
                        >
                            Сохранить и закрыть
                        </q-tooltip>
                    </q-btn>
                </template>
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";

import {
    REQUEST_STATUS_VALUES,
    REQUEST_KINDS_VALUES,
    GUARANTY_TYPES_VALUES,
    REPAIRS_TYPES_VALUES,
    PERFORM_RESULTS_IDS,
    OPTIONS_VIEW_BEFORE_VALUES,
    RIGHTS_CONSTANTS,
    PERFORM_KIND,
    CONTACT_KIND,
    REPAIRS_RESULTS_IDS,
    TOOLS_STATES_VALUES,
} from "@/store/constants";

import Documents from "@/components/Documents/Documents.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import DialogEditRequestCommon from "./DialogEditRequestCommon.vue";
import DialogEditRequestMoving from "./DialogEditRequestMoving.vue";
import DialogEditRequestHistory from "./DialogEditRequestHistory.vue";
import DialogEditRequestDocuments from "./DialogEditRequestDocuments.vue";
import DialogEditRequestComments from "./DialogEditRequestComments.vue";
import DialogEditRequestHotline from "./DialogEditRequestHotline.vue";

export default {
    name: "DialogEditRequest",
    components: {
        Documents,
        DialogEditRequestMoving,
        DialogEditRequestCommon,
        DialogEditRequestHistory,
        DialogEditRequestDocuments,
        DialogEditRequestComments,
        DialogEditRequestHotline,
    },
    setup() {
        return {
            RIGHTS_CONSTANTS,
            REQUEST_KINDS_VALUES,
            PERFORM_KIND,
        };
    },
    data() {
        return {
            tabCurrent: "info",
            validations: {},
            requestSaving: false,
            maximizedToggle: false,
            requestHash: "",
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            getUserFIO: "getUserFIO",
            getReqKindTitle: "getReqKindTitle",
            getReqStatusTitle: "getReqStatusTitle",
            requestDocs: "requestDocs",
            currentUser: "currentUser",
            filesList: "filesList",
            requestEngineers: "requestEngineers",
            requestHistory: "requestHistory",
            requestIsLoading: "requestIsLoading",
        }),
        reqTypeString() {
            return this.request.id
                ? this.getReqKindTitle(this.request.request_kind_id)
                : "Ожидание регистрации";
        },
        reqStatusString() {
            if (!this.request.id) {
                return "Ожидание регистрации";
            }

            return this.getReqStatusTitle(this.request.request_status_id);
        },
        canBeEdited() {
            return !this.request.number ||
                !!(this.currentUser.rightsObject.RIGHT_REQUEST_EDIT & RIGHTS_CONSTANTS.FULL);
        },
        isHotlineRequest() {
            return (
                this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL &&
                [
                    REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                    REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
                    REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                ].includes(this.request.request_status_id)
            );
        },
        requestCompleted() {
            return [
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                REQUEST_STATUS_VALUES.PERFORMED,
                REQUEST_STATUS_VALUES.CANCELED,
                REQUEST_STATUS_VALUES.CLOSED,
            ].includes(this.request.request_status_id);
        },
    },
    methods: {
        show() {
            this.tabCurrent = "info";
            this.validations = {};
            this.$refs.dialog.show();

            this.requestHash = JSON.stringify({
                request: this.request,
                filesList: this.filesList,
                requestDocs: this.requestDocs,
            });
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async saveRequest(doHide) {
            if (this.requestSaving) {
                return;
            }

            let tabName = "info";

            // validations - start
            this.validations = {};

            if (this.request.id &&
                this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                (this.request.repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT ||
                    this.request.repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_MONEY) &&
                !this.request.service_repair_id) {
                tabName = "tab2";
                this.validations = {
                    ...this.validations,
                    service_repair_id: true,
                };
            }

            if (![PERFORM_KIND.SENIOR, PERFORM_KIND.FILIAL].includes(this.request.request_performer_kind)) {
                this.validations = {
                    ...this.validations,
                    request_performer_kind: true,
                };
            }

            if (![CONTACT_KIND.FROM_IAC, CONTACT_KIND.STRANGER].includes(this.request.contact_kind)) {
                this.validations = {
                    ...this.validations,
                    contact_kind: true,
                };
            }

            if (!this.request.unit_id) {
                this.validations = {
                    ...this.validations,
                    unit_id: true,
                };
            }

            if (this.request.contact_kind === CONTACT_KIND.FROM_IAC && !this.request.contact_id) {
                this.validations = {
                    ...this.validations,
                    contact_id: true,
                };
            }

            if (this.request.contact_kind === CONTACT_KIND.STRANGER &&
                !/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(this.request.contact_unknown_email)) {
                this.validations = {
                    ...this.validations,
                    contact_unknown_email: true,
                };
            }

            if ((this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL ||
                this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON) &&
                !this.request.program_id) {
                tabName = "tab2";
                this.validations = {
                    ...this.validations,
                    program_id: true,
                };
            }

            if ((this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON ||
                this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) &&
                !this.request.tool_id) {
                tabName = "tab2";
                this.validations = {
                    ...this.validations,
                    tool_id: true,
                };
            }

            if (!this.request.request_text || this.request.request_text.length < 5) {
                this.validations = {
                    ...this.validations,
                    request_text: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.request_date_add &&
                this.request.tool_in_dt &&
                (date.extractDate(this.request.tool_in_dt, "DD.MM.YYYY").valueOf() <
                    date.extractDate(this.request.request_date_add, "YYYY-MM-DD").valueOf())) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    tool_in_dt: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.tool_in_dt &&
                this.request.date_repair &&
                (date.extractDate(this.request.date_repair, "YYYY-MM-DD HH:mm:ss").valueOf() <
                    date.extractDate(this.request.tool_in_dt, "DD.MM.YYYY").valueOf())) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    date_repair: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.tool_in_dt &&
                this.request.date_pass &&
                (date.extractDate(this.request.date_pass, "DD.MM.YYYY").valueOf() <
                    date.extractDate(this.request.tool_in_dt, "DD.MM.YYYY").valueOf())) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    date_pass: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.date_return &&
                this.request.date_pass &&
                (date.extractDate(this.request.date_return, "DD.MM.YYYY").valueOf() <
                    date.extractDate(this.request.date_pass, "DD.MM.YYYY").valueOf())) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    date_return: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.date_return &&
                this.request.date_pass &&
                (date.extractDate(this.request.tool_out_dt, "DD.MM.YYYY").valueOf() <
                    date.extractDate(this.request.tool_in_dt, "DD.MM.YYYY").valueOf())) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    tool_out_dt: true,
                };
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                !!this.request.repair_type_id &&
                (
                    (
                        this.request.repair_kind_id === GUARANTY_TYPES_VALUES.GUARANTY &&
                        this.request.repair_type_id !== REPAIRS_TYPES_VALUES.GUARANTY
                    ) ||
                    (
                        this.request.repair_kind_id !== GUARANTY_TYPES_VALUES.GUARANTY &&
                        this.request.repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY
                    )
                )
            ) {
                tabName = "tab2";

                this.validations = {
                    ...this.validations,
                    repair_type_id: true,
                };
            }

            if (Object.keys(this.validations).length > 0) {
                this.tabCurrent = tabName;
                return;
            }
            // validations - finish

            //------------------------------------------------------------------
            this.requestSaving = true;

            // check request status

            if (
                this.request.request_status_id === REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING &&
                (
                    this.request.hotline_result_id === PERFORM_RESULTS_IDS.FULFILED ||
                    this.request.result === PERFORM_RESULTS_IDS.FULFILED_HOTLINE
                )
            ) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "hotline_result_date",
                        value: new Date(),
                    },
                );
            }
            else if (
                [
                    REQUEST_STATUS_VALUES.PERFORMING,
                    REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                ].includes(this.request.request_status_id) &&
                this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL &&
                this.request.result === PERFORM_RESULTS_IDS.MOVED_HOTLINE
            ) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.HOT_LINE_WAITING,
                    },
                );
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_performer_kind",
                        value: PERFORM_KIND.SENIOR,
                    },
                );
            }
            else if (this.request.request_status_id === REQUEST_STATUS_VALUES.PERFORMING) {
                // check if request was performed
                let docStarts = "__DOCUMENT_DOES_NOT_EXIST__";

                if (this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL ||
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON ||
                    this.request.request_kind_id === REQUEST_KINDS_VALUES.RESTORE) {
                    docStarts = "_actCO3_";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS
                ) {
                    docStarts = "_actCO7_";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    docStarts = "_actCO8_";
                }
                else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.LEARNING) {
                    docStarts = "act_learning_";
                }

                if (this.request.request_kind_id === REQUEST_KINDS_VALUES.CONSULTATION ||
                    this.requestDocs
                        .map((rd) => rd.file_name)
                        .findIndex((rd) => (
                            rd.includes(docStarts) &&
                            rd.endsWith(".docx")
                        )) >= 0) {
                    await this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "request_status_id",
                            value: REQUEST_STATUS_VALUES.PERFORMED,
                        },
                    );

                    if (!this.request.result_date) {
                        await this.$store.dispatch(
                            "setRequestField",
                            {
                                field: "result_date",
                                value: date.formatDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
                            },
                        );
                    }
                }
            }

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.MISTAKE) {
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: REQUEST_STATUS_VALUES.CANCELED,
                    },
                );
            }
            else if (this.request.request_status_id === REQUEST_STATUS_VALUES.CANCELED) {
                // restore canceled request
                await this.$store.dispatch(
                    "setRequestField",
                    {
                        field: "request_status_id",
                        value: this.requestEngineers.length > 0
                            ? REQUEST_STATUS_VALUES.PERFORMING
                            : REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                    },
                );
            }

            this.$store.dispatch("saveRequest", !doHide)
                .then((message) => {
                    if (doHide) {
                        this.hide();
                    }

                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });

                    if (
                        this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                        [
                            REQUEST_STATUS_VALUES.PERFORMING,
                            REQUEST_STATUS_VALUES.PERFORMED,
                            REQUEST_STATUS_VALUES.CLOSED,
                        ].includes(this.request.request_status_id)
                    ) {
                        if (this.request.repair_result_id === REPAIRS_RESULTS_IDS.FULL) {
                            this.$store.dispatch(
                                "updateSingleToolInDB",
                                {
                                    id: this.request.tool_id,
                                    state: TOOLS_STATES_VALUES.IN_WORK,
                                },
                            );
                        }
                        else if (!this.request.repair_result_id) {
                            if (this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS) {
                                this.$store.dispatch(
                                    "updateSingleToolInDB",
                                    {
                                        id: this.request.tool_id,
                                        state: TOOLS_STATES_VALUES.WAITING_RECLAIM,
                                    },
                                );
                            }
                            if (this.request.view_res_before === OPTIONS_VIEW_BEFORE_VALUES.REPAIR_WAITING) {
                                this.$store.dispatch(
                                    "updateSingleToolInDB",
                                    {
                                        id: this.request.tool_id,
                                        state: TOOLS_STATES_VALUES.BROKEN,
                                    },
                                );
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err.message,
                    });
                })
                .finally(() => {
                    this.requestSaving = false;
                });
        },
        addDoc(file) {
            this.$store.dispatch("addRequestFile", file);
        },
        delDoc(index) {
            this.$store.dispatch("delRequestFile", index);
        },
        closeRequest() {
            if (!this.canBeEdited) {
                this.hide();
                return;
            }

            const requestHashL = JSON.stringify({
                request: this.request,
                filesList: this.filesList,
                requestDocs: this.requestDocs,
            });

            const requestWasChanged = requestHashL !== this.requestHash;

            if (!requestWasChanged) {
                this.hide();
            }
            else {
                this.$q.dialog({
                    component: DialogConfirm,
                    componentProps: {
                        dialogMessage: "Заявка была изменена. Сохранить изменения?",
                        labelOk: "Сохранить",
                    },
                })
                    .onOk(() => {
                        this.saveRequest(true);
                    })
                    .onCancel(() => {
                        this.hide();
                    });
            }
        },
        recalRequest() {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Отозвать заявку?",
                    labelOk: "Отозвать",
                },
            })
                .onOk(async () => {
                    await this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "request_kind_id",
                            value: REQUEST_KINDS_VALUES.REVOKE,
                        },
                    );
                    await this.$store.dispatch(
                        "setRequestField",
                        {
                            field: "request_status_id",
                            value: REQUEST_STATUS_VALUES.CANCELED,
                        },
                    );

                    this.saveRequest(true);
                });
        },
    },
};
</script>
