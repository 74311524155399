<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Характеристика вида учетной единицы
                </div>
            </q-card-section>

            <q-card-section
                class="scroll q-pt-none"
                style="max-height: 70vh; height: 60vh;"
            >
                <q-input
                    v-model="trait.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                />

                <div class="simple-three-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Значения параметра
                    </div>
                    <div class="cell cell-header cell-center">
                        <q-btn
                            round
                            :icon="matAdd"
                            size="sm"
                            color="primary"
                            @click="addValue"
                        >
                            <q-tooltip
                                :delay="300"
                                anchor="top right"
                                self="bottom middle"
                                class="bg-amber text-body2 text-black shadow-4"
                            >
                                Добавить параметр
                            </q-tooltip>
                        </q-btn>
                    </div>
                    <div
                        v-for="(value, index) in trait.values"
                        :key="index"
                        class="row-wrapper"
                    >
                        <div class="cell cell-center">
                            {{ index + 1 }}
                        </div>
                        <div class="cell">
                            <q-input
                                ref="refName"
                                v-model="trait.values[index].value_title"
                                borderless
                                dense
                                autogrow
                                :rules="[val => val &&
                                    0 < val.trim().length &&
                                    val.trim().length < 100 || 'Наименование от 1 до 100 символов']"
                                hideBottomSpace
                            />
                        </div>
                        <div class="cell cell-center">
                            <q-btn
                                round
                                :icon="matDelete"
                                size="sm"
                                class="poeso-table--control-button"
                                @click="removeValue(index)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogEditToolKindTrait",
    props: {
        traitOriginal: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            trait: {},
        };
    },
    computed: {
        ...mapGetters({
            traits: "traits",
        }),
    },
    methods: {
        show() {
            this.trait = {
                ...this.traitOriginal,
            };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        addValue() {
            this.trait.values.push({
                value_id: null,
                value_title: "",
            });
        },
        removeValue(traitIndex) {
            this.trait.values.splice(traitIndex, 1);
        },
        onOkClick() {
            this.$emit(
                "ok",
                this.trait,
            );
            this.hide();
        },
    },
};
</script>
