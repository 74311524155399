<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Регион
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    ref="regionName"
                    v-model="item.title"
                    outlined
                    dense
                    label="Наименование"
                    :rules="[val => val && val.length > 0 || 'Введите наименование региона']"
                    class="q-mb-sm"
                    hideBottomSpace
                />
                <q-input
                    ref="regionCode"
                    v-model="item.code"
                    outlined
                    dense
                    label="Код"
                    :rules="[val => val && val > 0 || 'Введите код региона']"
                    class="q-mb-sm"
                    hideBottomSpace
                />
                <q-input
                    ref="regionFilialTitle"
                    v-model="item.filial_title"
                    outlined
                    dense
                    label="Наименование и адрес филиала региона"
                    :rules="[val => val && val.length > 0 || 'Введите наименование филиала']"
                    class="q-mb-sm"
                    hideBottomSpace
                />
                <q-input
                    ref="regionFilialPhone"
                    v-model="item.filial_phone"
                    outlined
                    dense
                    label="Контактный телефон филиала региона"
                    :rules="[val => val && val.length > 0 || 'Введите телефон филиала']"
                    class="q-mb-sm"
                    hideBottomSpace
                />
                <q-input
                    ref="regionInstrumentalCase"
                    v-model="item.instrumental_case"
                    outlined
                    dense
                    label="Наименование в творительном падеже"
                    :rules="[val => val && val.length > 0 || 'Введите наименование в творительном падеже']"
                    class="q-mb-sm"
                    hideBottomSpace
                />
                <q-field
                    v-if="allowChangeEmailSetting"
                    label="Отправка сообщений инженеру"
                    stackLabel
                    outlined
                >
                    <q-option-group
                        v-model="item.immediate_email_to_engineer"
                        :options="immediateEmailOptions"
                        type="radio"
                    />
                </q-field>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "DialogEditRegion",
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                id: null,
                title: "",
                code: null,
                immediate_email_to_engineer: false,
            }),
        },
    },
    emits: ["ok"],
    data() {
        return {
            item: {},
            immediateEmailOptions: [
                { label: "Не отправлять автоматически", value: false },
                { label: "Отправлять автоматически", value: true },
            ],
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
        allowChangeEmailSetting() {
            return !!(this.currentUser.rightsObject.RIGHT_SET_ENGINEER_EMAIL & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        show() {
            this.item = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.regionName.validate();
            this.$refs.regionCode.validate();
            this.$refs.regionFilialTitle.validate();
            this.$refs.regionFilialPhone.validate();
            this.$refs.regionInstrumentalCase.validate();

            if (
                !this.$refs.regionName.hasError &&
                !this.$refs.regionCode.hasError &&
                !this.$refs.regionFilialTitle.hasError &&
                !this.$refs.regionFilialPhone.hasError &&
                !this.$refs.regionInstrumentalCase.hasError
            ) {
                this.$emit("ok", this.item);

                this.hide();
            }
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
