<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Список заявок ({{ reqsCount }})
            <q-space />
            <FilterRequests />
        </div>

        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="reqsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>

        <div class="requests-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                ГЛ / Филиал
            </div>
            <div class="cell cell-header cell-center">
                Дата регистрации
            </div>
            <div class="cell cell-header cell-center">
                Номер
            </div>
            <div class="cell cell-header cell-center">
                Номер на ГЛ
                <q-tooltip
                    anchor="top middle"
                    self="center middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Номер на "Горячей Линии"
                </q-tooltip>
            </div>
            <div class="cell cell-header cell-center">
                Дата исполнения
            </div>
            <div class="cell cell-header cell-center">
                Организация
            </div>
            <div class="cell cell-header cell-center">
                Тип
            </div>
            <div class="cell cell-header cell-center">
                Состояние
            </div>
            <div class="cell cell-header cell-center">
                Содержание
            </div>
            <div class="cell cell-header" />
            <!-- data -->
            <div
                v-for="(request, requestIndex) in requests"
                :key="requestIndex"
                :class="[
                    'row-wrapper',
                    getColorClass(request.request_kind_id, request.request_status_id)
                ]"
                @click="startEdit(requestIndex)"
            >
                <div class="cell cell-center">
                    {{ (currentPage - 1) * 20 + requestIndex + 1 }}
                </div>
                <div class="cell cell-center">
                    <div
                        v-if="request.request_performer_kind === 1"
                        class="hot-line-logo"
                    >
                        ГЛ
                    </div>
                </div>
                <div class="cell cell-center">
                    {{ $moment(request.request_date_add).format("DD.MM.YYYY") }}
                </div>
                <div class="cell cell-center">
                    {{ request.number }}
                </div>
                <div class="cell cell-center">
                    {{ request.hotlinegas_number }}
                </div>
                <div class="cell cell-center">
                    {{ request.result_date
                        ? $moment(request.result_date).format("DD.MM.YYYY")
                        : "" }}
                </div>
                <div
                    class="cell"
                    v-html="formUnitName(request.unit_id, request.contact_id)"
                />
                <div class="cell cell-center">
                    {{ getReqKindTitle(request.request_kind_id) }}
                </div>
                <div class="cell cell-center">
                    {{ getReqStatusTitle(request.request_status_id) }}
                </div>
                <div class="cell">
                    {{ request.request_text }}
                </div>
                <div
                    class="cell cell-center"
                    style="cursor: default;"
                    @click="(event) => event.stopPropagation()"
                >
                    <q-btn
                        v-if="allowDelRequest"
                        round
                        :icon="matDelete"
                        size="sm"
                        class="poeso-table--control-button"
                        @click="deleteRequest(requestIndex)"
                    />
                </div>
            </div>
        </div>
        <div class="app-controls">
            <ButtonNewItem
                v-if="allowCreateRequest"
                buttonToolTip="Создать заявку"
                @start-edit="startEdit"
            />
            <ButtonNewItem
                buttonToolTip="Выгрузить текущий список заявок"
                :icon="matDownload"
                @start-edit="createRequestsList"
            />
        </div>
    </div>
    <div
        v-if="showSpinner"
        class="req-dialog-cover"
    >
        <q-spinner-grid
            color="primary"
            size="5.5em"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import { tCellP } from "@/helpers/tableCell";

import { REQUEST_KINDS_VALUES, RIGHTS_CONSTANTS } from "@/store/constants";

import FilterRequests from "@/components/Filters/FilterRequests.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import ButtonNewItem from "@/components/Common/ButtonNewItem.vue";
import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";

export default {
    name: "Requests",
    components: {
        FilterRequests,
        ButtonNewItem,
    },
    setup() {
        return {
            REQUEST_KINDS_VALUES,
        };
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            requests: "requests",
            getReqKindTitle: "getReqKindTitle",
            getReqStatusTitle: "getReqStatusTitle",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
            reqsPagesCount: "reqsPagesCount",
            reqsPageNumber: "reqsPageNumber",
            currentUser: "currentUser",
            unitsFull: "unitsFull",
            reqsCount: "reqsCount",
        }),
        currentPage: {
            get() {
                return this.reqsPageNumber;
            },
            set(value) {
                this.$store.dispatch("setReqsPageNumber", value);
            },
        },
        allowCreateRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_CREATE &
                (RIGHTS_CONSTANTS.OWN | RIGHTS_CONSTANTS.ALL));
        },
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
    },
    mounted() {
        this.$store.dispatch("cleanReqsFilter");
    },
    methods: {
        getColorClass(requestKindId, requestStatusId) {
            if (requestKindId === REQUEST_KINDS_VALUES.MISTAKE ||
                requestKindId === REQUEST_KINDS_VALUES.REVOKE) {
                return "request-kind__mistake";
            }
            return `request-status__${requestStatusId}`;
        },
        startEdit(index) {
            let requestForEdit = {};
            if (index !== undefined) {
                requestForEdit = JSON.parse(JSON.stringify(this.requests[index]));
            }

            this.$store.dispatch("setRequest", requestForEdit)
                .then(() => {
                    this.$q.dialog({
                        component: DialogEditRequest,
                    });
                });
        },
        deleteRequest(index) {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить заявку № ${this.requests[index].number}?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteRequest", this.requests[index].id)
                        .then(() => {
                            this.$q.notify({
                                type: "notify-success",
                                message: "Заявка удалена",
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        formUnitName(unitId, contactId) {
            const emplPostTitle = this.getUserPostTitle(contactId);
            const unit = this.unitsFull.find((u) => u.id === unitId);
            return [
                unit ? unit.name : "",
                unit ? unit.address : "",
                this.getUserFIO(contactId),
                emplPostTitle ? `${emplPostTitle}` : "",
            ].join("<br>");
        },
        async getRequestsTable(requestsAll) {
            const tableWidth = 15285;

            const headerAttrs = {
                vAlign: "center",
                pAlign: "center",
                pPrBold: true,
                fontSize: 20,
            };

            const dataAttrs = {
                pAlign: "center",
                fontSize: 20,
            };

            const header = `
            <w:tblPr>
            <w:jc w:val="center"/>
            <w:tblW w:type="dxa" w:w="${tableWidth}"/>
            <w:tblBorders>
                <w:top w:val="single" w:sz="1" w:space="1" w:color="000000" />
                <w:start w:val="single" w:sz="1" w:space="1" w:color="000000" />
                <w:bottom w:val="single" w:sz="1" w:space="1" w:color="000000" />
                <w:end w:val="single" w:sz="1" w:space="1" w:color="000000" />
                <w:insideH w:val="single" w:sz="1" w:space="1" w:color="000000" />
                <w:insideV w:val="single" w:sz="1" w:space="1" w:color="000000" />
            </w:tblBorders>
            <w:tblCellMar>
                <w:top w:w="100" w:type="dxa"/>
                <w:start w:w="100" w:type="dxa"/>
                <w:bottom w:w="100" w:type="dxa"/>
                <w:end w:w="100" w:type="dxa"/>
            </w:tblCellMar>
            </w:tblPr>
            <w:tblGrid>
                <w:gridCol w:w="${Math.round(tableWidth * 0.035)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.165)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.195)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.130)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.110)}" />
                <w:gridCol w:w="${Math.round(tableWidth * 0.095)}" />
            </w:tblGrid>
            <w:tr>
            ${tCellP("№ п/п", headerAttrs)}
            ${tCellP("№ заявки", headerAttrs)}
            ${tCellP("Дата регистрации", headerAttrs)}
            ${tCellP("№ заявки на ГЛ", headerAttrs)}
            ${tCellP("Организация, ФИО заявителя, должность", headerAttrs)}
            ${tCellP("Содержание", headerAttrs)}
            ${tCellP("Тип заявки", headerAttrs)}
            ${tCellP("Состояние", headerAttrs)}
            ${tCellP("Дата исполнения", headerAttrs)}
            </w:tr>
            <w:tr>
            ${tCellP("1", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("2", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("3", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("4", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("5", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("6", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("7", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("8", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            ${tCellP("9", { pAlign: "center", pPrBold: true, fontSize: 20 })}
            </w:tr>
            `;
            let data = "";

            for (let i = 0; i < requestsAll.length; i++) {
                const request = requestsAll[i];

                const row = [
                    tCellP(i + 1, dataAttrs),
                    tCellP(request.number, dataAttrs),
                    tCellP(this.$moment(request.request_date_add).format("DD.MM.YYYY"), dataAttrs),
                    tCellP(request.hotlinegas_number || "", dataAttrs),
                    tCellP(
                        this.formUnitName(request.unit_id, request.contact_id)
                            .replace(/<br>/g, "\n"),
                        dataAttrs,
                    ),
                    tCellP(
                        request.request_text.replace(/"/g, "\u{201D}"),
                        dataAttrs,
                    ),
                    tCellP(
                        this.getReqKindTitle(request.request_kind_id),
                        dataAttrs,
                    ),
                    tCellP(
                        this.getReqStatusTitle(request.request_status_id),
                        dataAttrs,
                    ),
                    tCellP(
                        request.result_date ? this.$moment(request.result_date).format("DD.MM.YYYY") : "",
                        dataAttrs,
                    ),
                ]
                    .join("");
                // .replace(/"/g, "A");
                // .replace(/&quot;/g, "\"");

                data = `${data}<w:tr>${row}</w:tr>`;
            }

            return `<w:tbl>${header}${data}</w:tbl>`;
        },
        createRequestsList() {
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Формирование документа может занять продолжительное время",
                    labelOk: "Продолжить",
                    labelCancel: "Отменить",
                },
            })
                .onOk(async () => {
                    this.showSpinner = true;
                    const allRequests = await this.$store.dispatch("getAllRequestsFromDB");

                    const replacement = {
                        tableRequestsList: await this.getRequestsTable(allRequests),
                    };

                    createDoc(
                        "requests-list.docx",
                        "requests-list.docx",
                        replacement,
                    )
                        .then((requestsList) => {
                            saveDoc(requestsList);
                            this.showSpinner = false;
                        });
                });
        },
    },
};
</script>
