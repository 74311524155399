<template>
    <div class="cell cell-center">
        {{ orderN }}
    </div>
    <div class="cell">
        <q-icon
            v-if="doWorkWithSigns"
            :name="file.sign_type === 'electron' ? mdiUsbFlashDrive : mdiFileSign"
            size="1.5rem"
            class="q-mr-sm"
        >
            <q-tooltip
                :delay="300"
                anchor="top right"
                self="bottom middle"
                class="bg-amber text-body2 text-black shadow-4"
            >
                {{ file.sign_type === "electron"
                    ? "Документ для подписания посредством ЭЦП"
                    : "Документ для подписания на бумажном носителе" }}
            </q-tooltip>
        </q-icon>
        {{ file.file_name }}
    </div>
    <!-- save original doc -->
    <div class="cell">
        <q-btn
            v-if="file.path || file.blob"
            round
            :icon="mdiDownload"
            size="sm"
            class="poeso-table--control-button"
            @click="() => {
                if (file.path) {
                    saveDocAsFile();
                }
                if (file.blob) {
                    saveBlobAsFile();
                }
            }"
        >
            <q-tooltip
                :delay="300"
                anchor="top right"
                self="bottom middle"
                class="bg-amber text-body2 text-black shadow-4"
            >
                Сохранить оригинальный документ
            </q-tooltip>
        </q-btn>
    </div>
    <!-- save original and signs as zip -->
    <div
        v-if="doWorkWithSigns"
        class="cell"
    >
        <q-btn
            v-if="file.path && file.request_file_signers && file.request_file_signers.length > 0"
            round
            :icon="mdiDownloadMultiple"
            size="sm"
            class="poeso-table--control-button"
            @click="() => {
                saveDocAsZipWithSigns(file.path, file.file_name, file);
            }"
        >
            <q-tooltip
                :delay="300"
                anchor="top right"
                self="bottom middle"
                class="bg-amber text-body2 text-black shadow-4"
            >
                Сохранить документ и подписи в виде архива
            </q-tooltip>
        </q-btn>
    </div>
    <!-- sign iac -->
    <div
        v-if="doWorkWithSigns"
        class="cell cell-center"
    >
        <DocumentsRowSignIcon
            v-if="file.sign_type === 'electron'"
            :docSign="docSignIac"
            :signerId="file.signer_iac_id"
        />
    </div>
    <!-- sign unit -->
    <div
        v-if="doWorkWithSigns"
        class="cell cell-center"
    >
        <DocumentsRowSignIcon
            v-if="file.sign_type === 'electron'"
            :docSign="docSignUnit"
            :signerId="file.signer_unit_id"
        />
    </div>
    <!-- sign final -->
    <div
        v-if="doWorkWithSigns"
        class="cell cell-center"
    >
        <DocumentsRowSignIcon
            v-if="file.sign_type === 'electron' && file.signers_count == 3"
            :docSign="docSignFinal"
            :signerId="file.signer_final_id"
        />
    </div>
    <!-- save with images -->
    <div
        v-if="doWorkWithSigns"
        class="cell"
    >
        <q-btn
            v-if="file.path && file.request_file_signers && file.request_file_signers.length > 0"
            round
            :icon="mdiFileSign"
            size="sm"
            class="poeso-table--control-button"
            @click="() => {
                saveDocAsFileWithSignImage(file.path, file.file_name, file);
            }"
        >
            <q-tooltip
                :delay="300"
                anchor="top right"
                self="bottom middle"
                class="bg-amber text-body2 text-black shadow-4"
            >
                Сохранить документ с изображением подписи
            </q-tooltip>
        </q-btn>
    </div>
    <div class="cell">
        <q-btn
            round
            :icon="matDelete"
            size="sm"
            class="poeso-table--control-button"
            :disable="readonly"
            @click="() => delDoc(index)"
        />
    </div>
</template>

<script>
import { saveDocAsFileWithSignImage } from "@/helpers/createDoc";
import DocumentsRowSignIcon from "./DocumentsRowSignIcon.vue";

export default {
    name: "DocumentsRow",
    components: {
        DocumentsRowSignIcon,
    },
    props: {
        file: {
            type: Object,
            required: true,
        },
        orderN: {
            type: Number,
            default: 0,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        doWorkWithSigns: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "saveDocAsFile",
        "saveBlobAsFile",
        "saveDocAsZipWithSigns",
        "delDoc",
    ],
    setup() {
        return {
            saveDocAsFileWithSignImage,
        };
    },
    computed: {
        docSignIac() {
            return this.file.request_file_signers
                ? this.file.request_file_signers.find((s) => s.signer_kind === "iac") || {}
                : {};
        },
        docSignUnit() {
            return this.file.request_file_signers
                ? this.file.request_file_signers.find((s) => s.signer_kind === "unit") || {}
                : {};
        },
        docSignFinal() {
            return this.file.request_file_signers
                ? this.file.request_file_signers.find((s) => s.signer_kind === "final") || {}
                : {};
        },
    },
    methods: {
        saveDocAsFile() {
            this.$emit(
                "saveDocAsFile",
                this.file.path,
                this.file.file_name,
            );
        },
        saveBlobAsFile() {
            this.$emit(
                "saveBlobAsFile",
                this.file.blob,
                this.file.file_name,
            );
        },
        saveDocAsZipWithSigns() {
            this.$emit(
                "saveDocAsZipWithSigns",
                this.file.path,
                this.file.file_name,
                this.file,
            );
        },
        delDoc() {
            this.$emit("delDoc", this.orderN - 1);
        },
    },
};
</script>
