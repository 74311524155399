<template>
    <div class="row-wrapper">
        <div class="cell cell-center">
            {{ docNumber }}
        </div>
        <!-- DOCUMENT -->
        <div class="cell cell-center">
            <q-btn
                v-if="doc.request_number"
                :label="doc.request_number"
                style="width: 100%;"
                class="poeso-table--control-button"
                @click="showRequest"
            />
        </div>
        <div class="cell cell-doc">
            {{ doc.file_name }}
            <q-space />
            <q-btn
                v-if="docSignIac.path || docSignUnit.path || docSignFinal.path"
                round
                :icon="mdiFileSign"
                size="sm"
                class="poeso-table--control-button"
                @click="saveDocAsFileWithSignImage(doc.path, doc.file_name, doc)"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Сохранить документ с изображением подписи
                </q-tooltip>
            </q-btn>
            <q-btn
                round
                :icon="matDownload"
                size="sm"
                class="poeso-table--control-button"
                @click="saveDocAsFile(doc.path, doc.file_name)"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Сохранить оригинальный документ
                </q-tooltip>
            </q-btn>
        </div>
        <!-- SIGN IAC -->
        <div class="cell cell-doc">
            {{ getUserPostTitle(doc.signer_iac_id) }}
            {{ getUserFIO(doc.signer_iac_id) }}
            <q-space />
            <q-btn
                v-if="docSignIac.path"
                round
                :icon="matDownload"
                size="sm"
                class="poeso-table--control-button"
                @click="saveDocAsFile(docSignIac.path, `${doc.file_name}.iac.sig`)"
            />
            <q-btn
                v-else-if="doc.signer_iac_id == currentUser.id || currentUser.id === 141"
                round
                :icon="matHistoryEdu"
                size="sm"
                color="primary"
                @click="signDoc('iac')"
            />
        </div>
        <!-- SIGN UNIT -->
        <div class="cell cell-doc">
            {{ getUserPostTitle(doc.signer_unit_id) }}
            {{ getUserFIO(doc.signer_unit_id) }}
            <q-space />
            <q-btn
                v-if="docSignUnit.path"
                round
                :icon="matDownload"
                size="sm"
                class="poeso-table--control-button"
                @click="saveDocAsFile(docSignUnit.path, `${doc.file_name}.unit.sig`)"
            />
            <q-btn
                v-else-if="(doc.signer_unit_id == currentUser.id || currentUser.id === 141) && docSignIac.path"
                round
                :icon="matHistoryEdu"
                size="sm"
                color="primary"
                @click="signDoc('unit')"
            />
        </div>
        <!-- SIGN USD -->
        <div class="cell cell-doc">
            {{ getUserPostTitle(doc.signer_final_id) }}
            {{ getUserFIO(doc.signer_final_id) }}
            <q-space />
            <q-btn
                v-if="docSignFinal.path"
                round
                :icon="matDownload"
                size="sm"
                class="poeso-table--control-button"
                @click="saveDocAsFile(docSignFinal.path, `${doc.file_name}.final.sig`)"
            />
            <q-btn
                v-else-if="doc.signer_final_id == currentUser.id && docSignUnit.path"
                round
                :icon="matHistoryEdu"
                size="sm"
                color="primary"
                @click="signDoc('final')"
            />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import { getSingleRequest } from "@/helpers/serverDemands";
import DialogSign from "@/components/Dialogs/DialogSign.vue";
import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import { saveDocAsFileWithSignImage } from "@/helpers/createDoc";

export default {
    name: "DocumentsListRow",
    props: {
        doc: {
            type: Object,
            required: true,
        },
        docNumber: {
            type: Number,
            required: true,
        },
    },
    setup() {
        return {
            saveDocAsFileWithSignImage,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            getUserFIO: "getUserFIO",
            getUserPostTitle: "getUserPostTitle",
        }),
        docSignIac() {
            return this.doc.request_file_signers.find((s) => s.signer_kind === "iac") || {};
        },
        docSignUnit() {
            return this.doc.request_file_signers.find((s) => s.signer_kind === "unit") || {};
        },
        docSignFinal() {
            return this.doc.request_file_signers.find((s) => s.signer_kind === "final") || {};
        },
    },
    methods: {
        saveDocAsFile(url, fileName) {
            axios({
                url: `${this.siteHost}/${url}`,
                method: "GET",
                responseType: "blob",
            })
                .then((content) => {
                    const docForSign = new Blob([content.data]);
                    saveAs(docForSign, fileName);
                });
        },
        saveSign(docId, sign, fileName, signerKind) {
            const fileSign = new File(
                [sign.generatedSign],
                `${fileName}.sig`,
                {
                    type: "text/plain",
                },
            );
            this.$store.dispatch(
                "saveSign",
                {
                    sign: fileSign,
                    info: {
                        requestId: this.doc.request_id,
                        docId,
                        signerKind,
                        signerId: this.currentUser.id,
                        certificateObj: sign.certificateObj,
                    },
                },
            );
        },
        signDoc(signerKind) {
            axios({
                url: `${this.siteHost}/${this.doc.path}`,
                method: "GET",
                responseType: "blob",
            })
                .then((content) => {
                    const docForSign = new Blob([content.data]);
                    this.$q.dialog({
                        component: DialogSign,
                        componentProps: {
                            dataForSign: docForSign,
                        },
                    })
                        .onOk((sign) => {
                            this.saveSign(
                                this.doc.id,
                                sign,
                                this.doc.file_name,
                                signerKind,
                            );
                        });
                });
        },
        async showRequest() {
            const { request } = await getSingleRequest(this.doc.request_id);
            // console.log(req);

            await this.$store.dispatch("setRequest", request);
            this.$q.dialog({
                component: DialogEditRequest,
            });
        },
    },
};
</script>
