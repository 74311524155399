import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        requestComments: [],
    },
    getters: {
        requestComments: (state) => state.requestComments,
    },
    actions: {
        getRequestComments({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_comments/${reqId}`)
                    .then((res) => {
                        commit("setRequestComments", res.data.request_comments);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRequestComment({ dispatch, getters }, payload) {
            const formData = new FormData();
            formData.append(
                "requestComment",
                JSON.stringify({
                    request_id: payload.request_id,
                    comment: payload.comment,
                    user_id: getters.currentUser.id,
                }),
            );

            payload.files.forEach((file) => {
                if (file.blob) {
                    formData.append("requestCommentFiles", file.blob);
                }
            });

            axios.post(
                `${siteHost}/api/request_comments`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
                .then(() => {
                    dispatch("getRequestComments", payload.request_id);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        updateRequestComment({ commit }, comment) {
            commit("updateRequestComment", comment);
        },
        delRequestComment({ commit }) {
            commit("delRequestComment");
        },
    },
    mutations: {
        setRequestComments(state, requestComments) {
            state.requestComments = requestComments;
        },
        updateRequestComment(state, comment) {
            state.requestComments[0].comment = comment;
        },
        delRequestComment(state) {
            state.requestComments.shift();
        },
    },
};
