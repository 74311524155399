<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            {{ title }}
            ({{ orgsFiltered.length }})
            <q-space />
            <FilterOrganizations />
        </div>
        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="orgsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>
        <div class="content-body organizations-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Регион
            </div>
            <div class="cell cell-header cell-center">
                VNCODE
            </div>
            <div class="cell cell-header cell-center">
                *
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Адрес
            </div>
            <div class="cell cell-header cell-center">
                EMail
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    v-if="allowEditOrganization"
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="(e) => startEdit(e, null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(organizationF, index) in orgsOnPage"
                :key="index"
                class="row-wrapper"
                @click="(e) => startEdit(e, (currentPage - 1) * 20 + index)"
            >
                <div class="cell cell-center">
                    {{ (currentPage - 1) * 20 + index + 1 }}
                </div>
                <div class="cell cell-center">
                    {{ getRegionCode(organizationF.region_id) }}
                </div>
                <div class="cell cell-center">
                    {{ organizationF.vncode }}
                </div>
                <div class="cell cell-center">
                    <q-icon
                        v-if="organizationF.is_host"
                        :name="matPeople"
                    >
                        <q-tooltip
                            :delay="300"
                            anchor="top middle"
                            self="bottom middle"
                            class="bg-amber text-black text-body2"
                        >
                            Огранизация является балансодержателем
                        </q-tooltip>
                    </q-icon>
                </div>
                <div class="cell">
                    {{ organizationF.name }}
                </div>
                <div class="cell cell-column">
                    <div
                        v-for="(unit, indexO) in getUnitsOfOrg(organizationF.id)"
                        :key="indexO"
                    >
                        {{ unit.address }}
                    </div>
                </div>
                <div class="cell cell-center">
                    {{ organizationF.mail_address }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        v-if="allowEditOrganization"
                        round
                        :icon="matDelete"
                        size="sm"
                        class="poeso-table--control-button"
                        @click="(e) => deleteItem(e, organizationF)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { RIGHTS_CONSTANTS } from "@/store/constants";
import FilterOrganizations from "@/components/Filters/FilterOrganizations.vue";
import DialogEditOrganization from "@/components/Dialogs/DialogEditOrganization.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "Organizations",
    components: {
        FilterOrganizations,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // access to component instance via `vm`
            vm.$store.dispatch("setFilterOrg", { is_host: vm.isHost });
        });
    },
    props: {
        isHost: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            showDialog: false,
            organization: {},
            currentPage: 1,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            orgsFiltered: "orgsFiltered",
            getUnitsOfOrg: "getUnitsOfOrg",
            getRegionCode: "getRegionCode",
        }),
        orgsPagesCount() {
            return Math.floor(this.orgsFiltered.length / 20) + 1;
        },
        orgsOnPage() {
            return this.orgsFiltered.slice(
                (this.currentPage - 1) * 20,
                this.currentPage * 20,
            );
        },
        allowEditOrganization() {
            return !!(this.currentUser.rightsObject.RIGHT_ORGANIZATIONS & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        startEdit(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogEditOrganization,
                componentProps: {
                    organization: index !== null
                        ? { ...this.orgsFiltered[index] }
                        : {},
                },
            });
        },
        deleteItem(event, organization) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить организацию "${organization.name}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteOrganization", organization.id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
