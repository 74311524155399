<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />
                <q-select
                    v-model="orgId"
                    outlined
                    dense
                    clearable
                    :options="orgsFiltered"
                    optionValue="id"
                    optionLabel="name"
                    mapOptions
                    emitValue
                    label="Организация"
                />
                <SelectFilter
                    v-model="unitId"
                    :options="unitsFiltered"
                    label="Объект"
                    clearable
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    extendedScope
                />
                <q-select
                    v-model="roleId"
                    outlined
                    dense
                    clearable
                    :options="roles"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Роль"
                />
                <q-input
                    v-model="surname"
                    outlined
                    dense
                    clearable
                    label="Фамилия"
                />
                <q-input
                    v-model="name"
                    outlined
                    dense
                    clearable
                    label="Имя"
                />
                <q-input
                    v-model="patronymic"
                    outlined
                    dense
                    clearable
                    label="Отчество"
                />
                <q-input
                    v-model="login"
                    outlined
                    dense
                    clearable
                    label="Логин"
                />
                <q-select
                    v-model="postId"
                    outlined
                    dense
                    clearable
                    :options="posts"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Классификатор должностей"
                />
                <q-input
                    v-model="post"
                    outlined
                    dense
                    clearable
                    label="Должность"
                />
                <PoeDatePicker
                    v-model="dateVisitFrom"
                    labelTitle="Дата посещения от"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
                <PoeDatePicker
                    v-model="dateVisitTill"
                    labelTitle="Дата посещения по"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
            </div>
            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch(
                        'setFilterUsers',
                        {
                            region_id: null,
                            organization_id: null,
                            unit_id: null,
                            postId: null,
                            post: null,
                            roleId: null,
                            surname: null,
                            name: null,
                            patronymic: null,
                            login: null,
                            dateVisitFrom: null,
                            dateVisitTill: null,
                        },
                    )"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        round
        :icon="matSearch"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.filterUsers[property];
        },
        set(value) {
            this.$store.dispatch(
                "setFilterUsers",
                {
                    ...this.filterUsers,
                    [property]: value,
                },
            );
        },
    };
}

export default {
    name: "FilterUsers",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            orgs: "orgs",
            unitsFull: "unitsFull",
            posts: "posts",
            roles: "roles",
            filterUsers: "filterUsers",
            currentUser: "currentUser",
        }),
        orgsFiltered() {
            return this.regionId
                ? this.orgs.filter((o) => o.region_id === this.regionId)
                : this.orgs;
        },
        unitsFiltered() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => this.currentUser.units.includes(u.id));
        },
        regionId: generateProperty("region_id"),
        orgId: generateProperty("organization_id"),
        unitId: generateProperty("unit_id"),
        postId: generateProperty("postId"),
        post: generateProperty("post"),
        roleId: generateProperty("roleId"),
        surname: generateProperty("surname"),
        name: generateProperty("name"),
        patronymic: generateProperty("patronymic"),
        login: generateProperty("login"),
        dateVisitFrom: generateProperty("dateVisitFrom"),
        dateVisitTill: generateProperty("dateVisitTill"),
    },
    watch: {
        regionId(v) {
            if (v) {
                this.orgId = null;
            }
        },
    },
};
</script>
