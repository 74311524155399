<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Виды комплектов учетных единиц
            ({{ toolsSetsNames.length }})
        </div>
        <div class="content-body tools-sets-names-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Код
            </div>
            <div class="cell cell-header cell-center">
                Вид комплекта учетных единиц
            </div>
            <div class="cell cell-header cell-center">
                Система ТС
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(toolsSetName, toolsSetNameIndex) in toolsSetsNames"
                :key="toolsSetNameIndex"
                class="row-wrapper"
                @click="editItem(toolsSetNameIndex)"
            >
                <div class="cell cell-center">
                    {{ toolsSetNameIndex + 1 }}
                </div>
                <div class="cell">
                    {{ toolsSetName.code }}
                </div>
                <div class="cell">
                    {{ toolsSetName.name }}
                </div>
                <div class="cell">
                    {{ getSystemTsLabel(toolsSetName.system_ts_id) }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, toolsSetNameIndex)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    SYSTEMS_TS,
} from "@/store/constants";

import DialogEditToolsSetName from "@/components/Dialogs/DialogEditToolsSetName.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "ToolsSetsNames",
    computed: {
        ...mapGetters({
            toolsSetsNames: "toolsSetsNames",
        }),
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditToolsSetName,
                componentProps: {
                    itemForEdit: index === null
                        ? {
                            name: "",
                            code: "",
                            system_ts_id: null,
                        }
                        : {
                            name: this.toolsSetsNames[index].name,
                            code: this.toolsSetsNames[index].code,
                            system_ts_id: this.toolsSetsNames[index].system_ts_id,
                        },
                },
            })
                .onOk((toolsSetName) => {
                    this.$store.dispatch("saveToolsSetName", {
                        ...toolsSetName,
                        id: index === null ? null : this.toolsSetsNames[index].id,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: [
                        "Удалить вид комплекта оборудования",
                        `"${this.toolsSetsNames[index].name}"?`,
                    ].join(" "),
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteToolsSetName", this.toolsSetsNames[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        getSystemTsLabel(systemTsId) {
            const systemTs = SYSTEMS_TS.find((e) => e.value === systemTsId);

            return systemTs ? systemTs.label : "";
        },
    },
};
</script>
