<template>
    <q-card-section
        style="max-height: 70vh; height: 60vh;"
        class="scroll no-padding"
    >
        <div class="dialog-common-grid-col-2">
            <SelectFilter
                :modelValue="tool.set_id"
                :options="toolsSetsNamesCodes"
                label="Вид комплекта оборудования"
                optionLabel="name"
                class="cell cell-span-col-2"
                clearable
                :disable="!saveToDb || !canWriteTool"
                @update:modelValue="(v) => setToolAttr('set_id', v)"
            />
            <q-select
                ref="refToolKind"
                :modelValue="tool.tool_kind_id"
                outlined
                dense
                :options="toolsKinds"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Вид оборудования"
                class="cell cell-span-col-2"
                clearable
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[val => val && val > 0 || 'Укажите вид оборудования']"
                @update:modelValue="(v) => setToolAttr('tool_kind_id', v)"
            />
            <q-select
                ref="refManufacturer"
                v-model="toolManufacturerId"
                outlined
                dense
                :options="manufacturersLocal"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Производитель"
                class="cell cell-span-col-2"
                clearable
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[val => val && val > 0 || 'Укажите производителя оборудования']"
            />
            <q-select
                ref="refModel"
                v-model="toolModelId"
                outlined
                dense
                :options="toolsModelsNames"
                optionValue="id"
                optionLabel="model"
                mapOptions
                emitValue
                label="Наименование"
                class="cell cell-span-col-2"
                clearable
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[val => val && val > 0 || 'Укажите наименование оборудования']"
            />
            <q-input
                ref="refSerial"
                :modelValue="tool.serial_number"
                outlined
                dense
                class="cell"
                label="Серийный номер"
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[val => val && val.length > 0 || 'Укажите серийный номер']"
                @update:modelValue="(v) => setToolAttr('serial_number', v)"
            />
            <q-input
                ref="refInventar"
                :modelValue="tool.inventar_number"
                outlined
                dense
                class="cell"
                label="Инвентарный номер"
                hideBottomSpace
                :disable="!saveToDb || !canWriteTool"
                :errorMessage="inventarErrorMessage"
                :error="!inventarValidation"
                @update:modelValue="(v) => setToolAttr('inventar_number', v)"
            />
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
                class="cell-span-col-2"
                hideBottomSpace
                errorMessage="Нужно выбрать"
                :error="!regionId"
                :disable="!saveToDb || !canWriteTool"
            />
            <SelectFilter
                ref="refUnit"
                :modelValue="tool.unit_id"
                class="cell cell-span-col-2"
                label="Объект"
                :options="unitsFiltered"
                optionLabel="name"
                extendedScope
                scopeAttr1="name"
                scopeAttr2="not_needed"
                scopeAttr3="address"
                clearable
                hideBottomSpace
                :disable="!saveToDb || !canWriteTool"
                :rules="[val => val && val > 0 || 'Выберите объект']"
                @update:modelValue="(v) => setToolAttr('unit_id', v)"
            />
            <q-select
                :modelValue="tool.owner_type_id"
                outlined
                dense
                :options="TOOLS_OWNERS_TYPES"
                mapOptions
                emitValue
                label="Принадлежность оборудования"
                class="cell cell-span-col-2"
                :disable="!canWriteTool"
                @update:modelValue="(v) => setToolAttr('owner_type_id', v)"
            />
            <q-input
                ref="refCostRemain"
                :modelValue="tool.cost_remain"
                outlined
                dense
                class="cell cell-span-col-2"
                label="Остаточная стоимость"
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[(val) => {
                    if (val === null || vall === '') {
                        return true;
                    }

                    return /^\d+(\.?)\d{0,2}$/.test(val) || 'Число, два знака после запятой';
                }]"
                @update:modelValue="(v) => setToolAttr('cost_remain', v)"
            />
            <PoeDatePicker
                ref="refDate"
                :modelValue="tool.work_in_dt"
                labelTitle="Ввод в эксплуатацию"
                classOuter="cell"
                :futureDateAllowed="false"
                :disable="!saveToDb || !canWriteTool"
                @update:modelValue="setWorkInDt"
            />
            <q-select
                v-model="guarantyPeriod"
                outlined
                dense
                clearable
                :disable="!tool.work_in_dt || !canWriteTool"
                :options="guarantyPeriods"
                classOuter="cell"
                label="Срок гарантии"
                @update:modelValue="setGuarantyDtFromPeriod"
            />
            <PoeDatePicker
                :modelValue="tool.guaranty_dt"
                labelTitle="Дата срока гарантии"
                :doValidate="false"
                classOuter="cell"
                :disable="!tool.work_in_dt || !canWriteTool"
                @update:modelValue="(v) => setToolAttr('guaranty_dt', v)"
            />
            <q-input
                v-model="guarantyRemainsS"
                outlined
                dense
                classOuter="cell"
                label="Остаток гарантийного срока"
                readonly
            />

            <q-select
                ref="refState"
                :modelValue="tool.state"
                outlined
                dense
                :options="TOOLS_STATES"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Состояние"
                class="cell cell-span-col-2"
                clearable
                :disable="!canWriteTool"
                hideBottomSpace
                :rules="[val => val && val > 0 || 'Укажите состояние оборудования']"
                @update:modelValue="(v) => setToolAttr('state', v)"
            />
            <q-input
                :modelValue="tool.description"
                outlined
                dense
                class="cell cell-span-col-2"
                label="Примечание"
                autogrow
                :disable="!canWriteTool"
                @update:modelValue="(v) => setToolAttr('description', v)"
            />
        </div>
    </q-card-section>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import {
    TOOLS_STATES_VALUES,
    TOOLS_STATES,
    TOOLS_OWNERS_TYPES,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import { guarantyRemains } from "@/helpers/helpers";

export default {
    name: "DialogEditToolCommon",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    props: {
        tool: {
            type: Object,
            required: true,
        },
        saveToDb: {
            type: Boolean,
            required: true,
        },
        canWriteTool: {
            type: Boolean,
            required: true,
        },
    },
    // inject: ["tool"],
    emits: ["update:tool", "setTraitValues", "setFilesListDocs", "setFilesListPictures"],
    setup() {
        return {
            TOOLS_STATES,
            TOOLS_OWNERS_TYPES,
            guarantyPeriods: ["3 мес", "6 мес", "12 мес", "24 мес", "36 мес"],
        };
    },
    data() {
        return {
            guarantyPeriod: "",
            regionIdProxy: null,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            toolsKinds: "toolsKinds",
            manufacturers: "manufacturers",
            toolsModels: "toolsModels",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            getOrgProperty: "getOrgProperty",
            getOrgIdOfUnit: "getOrgIdOfUnit",
        }),
        toolManufacturerId: {
            get() {
                return this.manufacturersLocal.map((e) => e.id).includes(this.tool.manufacturer_id)
                    ? this.tool.manufacturer_id
                    : null;
            },
            set(v) {
                this.setToolAttr("manufacturer_id", v);
            },
        },
        toolModelId: {
            get() {
                return (!this.manufacturersLocal.map((e) => e.id).includes(this.tool.manufacturer_id)) ||
                    (!this.toolsModelsNames.map((m) => m.id).includes(this.tool.model_id))
                    ? null
                    : this.tool.model_id;
            },
            set(v) {
                this.setToolAttr("model_id", v);
                this.toolModelChange(v);
            },
        },
        manufacturersLocal() {
            return this.tool.tool_kind_id
                ? this.manufacturers
                    .filter((m) => this.toolsModels
                        .filter((model) => model.tool_kind_id === this.tool.tool_kind_id)
                        .map((model) => model.manufacturer_id)
                        .includes(m.id))
                : []; // this.manufacturers;
        },
        toolsModelsNames() {
            return this.tool.tool_kind_id && this.toolManufacturerId
                ? this.toolsModels.filter((m) => (
                    m.tool_kind_id === this.tool.tool_kind_id &&
                    m.manufacturer_id === this.toolManufacturerId
                ))
                    .sort((a, b) => (a.model.toLowerCase() < b.model.toLowerCase() ? -1 : 1))
                : [];
        },
        inventarValidation() {
            if (this.tool.inventar_number &&
                (this.tool.inventar_number.trim().length !== this.tool.inventar_number.length)) {
                return false;
            }

            return (
                this.tool.state === TOOLS_STATES_VALUES.WAITING ||
                (this.tool.state !== TOOLS_STATES_VALUES.WAITING && !!this.tool.inventar_number)
            );
        },
        inventarErrorMessage() {
            if (this.tool.inventar_number &&
                (this.tool.inventar_number.trim().length !== this.tool.inventar_number.length)) {
                return "Неверный формат";
            }

            return "Укажите инвентарный номер";
        },
        guarantyRemainsS() {
            return guarantyRemains(
                date.formatDate(
                    date.extractDate(this.tool.guaranty_dt, "DD.MM.YYYY"),
                    "YYYY-MM-DD 00:00:00",
                ),
            );
        },
        regionId: {
            get() {
                if (this.tool.unit_id) {
                    const orgId = this.getOrgIdOfUnit(this.tool.unit_id);
                    return this.getOrgProperty(orgId, "region_id");
                }

                return this.regionIdProxy;
            },
            set(v) {
                if (v !== this.regionIdProxy || v === null) {
                    this.regionIdProxy = v;
                    this.setToolAttr("unit_id", null);
                }
            },
        },
        unitsFiltered() {
            return this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;
        },
    },
    mounted() {
        if (this.tool.work_in_dt && this.tool.guaranty_dt) {
            const work_in_dt = date.extractDate(this.tool.work_in_dt, "DD.MM.YYYY");
            const guaranty_dt = date.extractDate(this.tool.guaranty_dt, "DD.MM.YYYY");
            const months = date.getDateDiff(guaranty_dt, work_in_dt, "months");
            if (months >= 36) {
                this.guarantyPeriod = "36 мес";
            }
            else if (months >= 24) {
                this.guarantyPeriod = "24 мес";
            }
            else if (months >= 12) {
                this.guarantyPeriod = "12 мес";
            }
            else if (months >= 6) {
                this.guarantyPeriod = "6 мес";
            }
            else if (months >= 3) {
                this.guarantyPeriod = "3 мес";
            }
        }
    },
    methods: {
        setToolAttr(attr, v) {
            this.$emit(
                "update:tool",
                {
                    ...this.tool,
                    [attr]: v,
                },
            );
        },
        async toolModelChange(modelId) {
            const traitValues = await this.$store.dispatch(
                "getToolKindTraitsValues",
                {
                    toolId: !!modelId ? modelId : null,
                    kindId: this.tool.tool_kind_id
                        ? this.tool.tool_kind_id
                        : null,
                },
            );
            this.$emit("setTraitValues", traitValues);

            if (modelId) {
                const filesListDocs = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_docs",
                        id: modelId,
                    },
                );
                this.$emit("setFilesListDocs", filesListDocs);

                const filesListPictures = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_model_pictures",
                        id: modelId,
                    },
                );
                this.$emit("setFilesListPictures", filesListPictures);
            }
        },
        setWorkInDt(value) {
            this.setToolAttr("work_in_dt", value);

            if (value && this.guarantyPeriod) {
                this.$nextTick(() => {
                    this.setGuarantyDtFromPeriod();
                });
            }
        },
        setGuarantyDtFromPeriod() {
            if (this.guarantyPeriod) {
                const work_in_dt = date.extractDate(this.tool.work_in_dt, "DD.MM.YYYY");
                const gMonths = +this.guarantyPeriod.split(" ")[0];
                const guaranty_dt = date.addToDate(work_in_dt, { months: gMonths });

                this.setToolAttr("guaranty_dt", date.formatDate(guaranty_dt, "DD.MM.YYYY"));
            }
        },
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                },
                false,
            );

            return hasErrors;
        },
    },
};
</script>
