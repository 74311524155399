<template>
    <div class="request-hotline-grid">
        <div class="cell-span-col-2">
            <q-btn
                filled
                color="primary"
                label="Принять к исполнению"
                :disable="requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_WAITING"
                @click="takeForPerform"
            />
        </div>
        <q-select
            v-model="hotlineResultId"
            :options="PERFORM_RESULTS_PO_COMMON"
            emitValue
            mapOptions
            outlined
            dense
            label="Результат исполнения"
            class="cell-span-col-6"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <q-select
            v-model="hotlineResultType"
            :options="PERFORM_TYPES"
            emitValue
            mapOptions
            outlined
            dense
            label="Вид исполнения"
            class="cell-span-col-2"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <q-field
            outlined
            dense
            label="Дата исполнения"
            :stackLabel="!!hotlineResultDate"
            color="dark"
            labelColor="grey-9"
            class="cell-span-col-2"
        >
            <div class="field-inner">
                {{ hotlineResultDate ? dateYMDtoDMY(hotlineResultDate) : "" }}
            </div>
        </q-field>
        <q-field
            outlined
            dense
            label="Время исполнения (дней)"
            :stackLabel="!!duration"
            color="dark"
            labelColor="grey-9"
            class="cell-span-col-2"
        >
            <div class="field-inner">
                {{ duration }}
            </div>
        </q-field>
        <q-input
            v-model="hotlineWorkDescription"
            outlined
            dense
            label="Описание выполненной работы"
            type="textarea"
            class="cell-span-col-6"
            :disable="readOnly || requestStatusId != REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING"
        />
        <!-- <q-btn
            filled
            color="primary"
            disable
            label="Сформировать Акт"
        />
        <q-field
            outlined
            dense
            label="№ акта"
            stackLabel
            readonly
        />
        <q-field
            outlined
            dense
            label="Дата акта"
            stackLabel
            readonly
        /> -->
        <template v-if="allowDelRequest">
            <q-btn
                filled
                color="primary"
                label="Назначить исполнителя"
                class="cell-span-col-2"
                @click="chooseEngineer"
            />
            <q-btn
                filled
                color="primary"
                label="Заявка выполнена"
                :disable="readOnly"
                class="cell-span-col-2"
                @click="requestFulfil"
            />
            <q-btn
                filled
                color="primary"
                label="На повторное исполнение"
                @click="requestRevive"
            />
            <q-btn
                filled
                color="primary"
                label="Отменить исполнение"
                :disable="disableResume"
                @click="resumeRequest"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import {
    PERFORM_RESULTS_PO_COMMON,
    PERFORM_RESULTS_IDS,
    REQUEST_STATUS_VALUES,
    PERFORM_TYPES,
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import { dateYMDtoDMY } from "@/helpers/helpers";
import DialogSearchEngineer from "@/components/Dialogs/DialogSearchEngineer.vue";
import generateRequestProperty from "@/helpers/generateRequestProperty";

export default {
    name: "DialogEditRequestHotline",
    setup() {
        return {
            PERFORM_RESULTS_PO_COMMON,
            REQUEST_STATUS_VALUES,
            PERFORM_TYPES,
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            request: "request",
        }),
        requestStatusId: generateRequestProperty("request_status_id"),
        result: generateRequestProperty("result"),
        resultType: generateRequestProperty("result_type"),
        resultDate: generateRequestProperty("result_date"),
        note: generateRequestProperty("note"),
        hotlineResultId: generateRequestProperty("hotline_result_id"),
        hotlineResultType: generateRequestProperty("hotline_result_type"),
        hotlineResultDate: generateRequestProperty("hotline_result_date"),
        hotlineWorkDescription: generateRequestProperty("hotline_work_description"),
        duration() {
            if (this.hotlineResultDate) {
                const diff = date.getDateDiff(
                    this.hotlineResultDate,
                    this.request.request_date_add,
                    "minutes",
                );

                // 24 * 60 = 1440
                const days = Math.floor(diff / 1440);
                const hours = Math.floor((diff - days * 1440) / 60);
                const minutes = diff - days * 1440 - hours * 60;

                return [
                    days ? `${days} д. ` : "",
                    hours ? `${hours} ч. ` : "",
                    minutes ? `${minutes} м. ` : "",
                ].join("");
            }

            return "";
        },
        allowDelRequest() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_DELETE & RIGHTS_CONSTANTS.FULL);
        },
        readOnly() {
            return ![
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMED,
                REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
            ].includes(this.requestStatusId);
        },
        disableResume() {
            const expiringDate = date.formatDate(
                date.subtractFromDate(
                    Date.now(),
                    { days: 3 },
                ),
                "YYYY-MM-DD HH:mm:ss",
            );

            return (
                this.request.request_status_id !== REQUEST_STATUS_VALUES.CLOSED ||
                !(this.currentUser.rightsObject.RIGHT_REQUEST_RESUME & RIGHTS_CONSTANTS.FULL) ||
                this.request.result_date < expiringDate
            );
        },
    },
    methods: {
        createAct() {
            console.log("CREATE ACT");
        },
        takeForPerform() {
            this.$store.dispatch("setRequestEngineers", [this.currentUser.id]);
            this.requestStatusId = REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING;
        },
        requestRevive() {
            this.requestStatusId = REQUEST_STATUS_VALUES.WAITING_ENGINEER;
            this.result = null;
        },
        chooseEngineer() {
            this.$q.dialog({
                component: DialogSearchEngineer,
                componentProps: {
                    dialogTitle: "Выбор исполнителя ТП",
                    itemForEdit: "",
                    workerType: "hotlineWokrer",
                },
            })
                .onOk((id) => {
                    this.$store.dispatch("setRequestEngineers", [id]);
                });
        },
        requestFulfil() {
            // (REQUEST_STATUS_VALUES.CLOSED)
            this.result = PERFORM_RESULTS_IDS.FULFILED_HOTLINE;
            this.resultType = this.hotlineResultType;
            this.hotlineResultDate = date.formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
            this.resultDate = date.formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
            this.note += this.hotlineWorkDescription;
        },
        resumeRequest() {
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "request_status_id",
                    value: REQUEST_STATUS_VALUES.HOT_LINE_PERFORMING,
                },
            );
        },
    },
};
</script>
