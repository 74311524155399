import date from 'quasar/src/utils/date/date.js';;
import {
    TOOLS_STATES,
} from "@/store/constants";

export function guarantyRemains(guaranty_dt, prefix = "") {
    if (!guaranty_dt) {
        return "Гарантийный срок не указан";
    }

    const guarantyDtYMD = date.extractDate(guaranty_dt, "YYYY-MM-DD");

    const diff = date.getDateDiff(
        guarantyDtYMD,
        new Date(),
        "days",
    );

    if (diff <= 0) {
        return "Гарантийный срок истек";
    }

    const years = Math.floor(diff / 365);
    const months = Math.floor((diff - years * 365) / 30);
    const days = diff - years * 365 - months * 30;

    return [
        prefix,
        years > 0 ? `${years} г. ` : "",
        months > 0 ? `${months} м. ` : "",
        days > 0 ? `${days} д.` : "",
    ].join("");
}

export function dateYMDtoDMY(d) {
    return d
        ? date.formatDate(
            date.extractDate(d, "YYYY-MM-DD"),
            "DD.MM.YYYY",
        )
        : date.formatDate(
            new Date(),
            "DD.MM.YYYY",
        );
}

export function dateDMYtoYMD(d) {
    return d
        ? date.formatDate(
            date.extractDate(d, "DD.MM.YYYY"),
            "YYYY-MM-DD",
        )
        : date.formatDate(
            new Date(),
            "YYYY-MM-DD",
        );
}

export function dateDiffYMD(d) {
    const dateNow = new Date();
    const dateAct = new Date(d);

    const iNowYear = dateNow.getFullYear();
    const iNowMonth = dateNow.getMonth();
    const iNowDay = dateNow.getDate();
    const iActYear = dateAct.getFullYear();
    const iActMonth = dateAct.getMonth();
    const iActDay = dateAct.getDate();

    let iDYear = iNowYear - iActYear;
    let iDMonth = iNowMonth - iActMonth;
    let iDDay = iNowDay - iActDay;

    if (iDDay < 0) {
        iDMonth--;
        iDDay += (new Date(iNowYear, iNowMonth - 1, 0).getDate());
    }

    if (iDMonth < 0) {
        iDYear--;
        iDMonth += 12;
    }

    return [
        iDYear ? `${iDYear} л.` : "",
        iDMonth ? `${iDMonth} м.` : "",
        iDDay ? `${iDDay} д.` : "",
    ].join(" ").trim();
}

export function toolsStateName(toolStateId) {
    const toolState = TOOLS_STATES.find((ts) => ts.id === toolStateId);
    return toolState
        ? toolState.title
        : "";
}

export function cellVS2({
    cell,
    value,
    bc = "000000",
    fill = "FFFFFF",
    fontSize = null,
}) {
    cell.value(value);
    cell.style("border", true);
    cell.style("borderColor", bc);
    cell.style("fill", fill);
    if (fontSize) {
        cell.style("fontSize", fontSize);
    }
    cell.style("fontFamily", "Arial");
}

export function saveFile(fileName, reportFile) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(reportFile, fileName);
    }
    else {
        const url2 = window.URL.createObjectURL(reportFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url2;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url2);
        document.body.removeChild(a);
    }
}
