<template>
    <div class="users-filter">
        <div
            v-if="showFilter"
            class="users-filter"
        >
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
            />

            <q-input
                v-model="code"
                outlined
                dense
                clearable
                label="Код"
                class="users-filter--input"
            >
                <template #append>
                    <q-checkbox v-model="codeExact">
                        <q-tooltip
                            :delay="250"
                            class="bg-amber text-body2 text-black shadow-4"
                        >
                            Точное совпадение
                        </q-tooltip>
                    </q-checkbox>
                </template>
            </q-input>
            <q-input
                v-model="title"
                outlined
                dense
                clearable
                label="Наименование"
                class="users-filter--input"
            >
                <template #append>
                    <q-checkbox v-model="titleExact">
                        <q-tooltip
                            :delay="250"
                            class="bg-amber text-body2 text-black shadow-4"
                        >
                            Точное совпадение
                        </q-tooltip>
                    </q-checkbox>
                </template>
            </q-input>
        </div>

        <q-btn
            round
            :icon="matSearch"
            @click="showFilter = !showFilter"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.sparesSetsItemsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setSparesSetsItemsFilter",
                {
                    ...this.sparesSetsItemsFilter,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterSparesSetsItems",
    components: {
        SelectFilter,
    },
    data() {
        return {
            showFilter: true,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            sparesSetsItemsFilter: "sparesSetsItemsFilter",
        }),
        regionId: generateProperty("regionId"),
        code: generateProperty("code"),
        title: generateProperty("title"),
        codeExact: generateProperty("codeExact"),
        titleExact: generateProperty("titleExact"),
    },
};
</script>
