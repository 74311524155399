<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета по подсистемам ГАС «Правосудие»
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    :futureDateAllowed="false"
                    labelTitle="Дата начала отчета"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    :icon="matSave"
                    color="primary"
                    flat
                    label="Сформировать"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    :icon="matCancel"
                    color="primary"
                    flat
                    label="Отменить"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "DialogCreateSubsystemReport",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            programsSubsystems: "programsSubsystems",
            programs: "programs",
            regions: "regions",
            currentUser: "currentUser",
        }),
    },
    watch: {
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refRegionId.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refRegionId.hasError && !this.allRegions)) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            let requestItems = [];

            for (let i = 0; i < this.regions.length; i++) {
                if (this.allRegions) this.valueProgress = Math.round((i / this.regions.length) * 100);
                const currentRegionId = this.regions[i].id;
                if ((this.allRegions && currentRegionId < 90) || (!this.allRegions && currentRegionId === this.regionId)) {
                    const { requests } = (await this.$store.dispatch(
                        "getRequestsSoft",
                        {
                            region_id: currentRegionId,
                            date_beg: this.dateStart,
                            date_end: this.dateFinsh,
                        },
                    ));
                    requestItems = requestItems.concat(requests);
                }
            }

            const subsystems = await this.getSubsystemReport(this.programsSubsystems, this.programs, requestItems);
            const outFileName = `Отчет по подсистемам ГАС «Правосудие» за период с ${this.dateStart} по ${this.dateFinsh}.docx`;

            const replacement = {
                region_name: this.getRegionName(this.regionId),
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                ss: subsystems,
                total_count: requestItems.length,
            };

            createDoc(
                this.allRegions ? "report-subsystem-all.docx" : "report-subsystem.docx",
                outFileName,
                replacement,
            )
                .then((report) => {
                    saveDoc(report);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        getRequestCount(requests, program_id) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].program_id === program_id) {
                    count += 1;
                }
            }
            return count;
        },
        async getSubsystemReport(programsSubsystems, programs, requests) {
            const items = [];
            let counter = 0;
            for (let i = 0; i < programsSubsystems.length; i++) {
                for (let j = 0; j < programs.length; j++) {
                    if (programs[j].gas_sub_system_id === programsSubsystems[i].id) {
                        counter += 1;
                        const item = {
                            n: counter,
                            subsystem_title: programsSubsystems[i].title,
                            pi_title: programs[j].title,
                            req_count: this.getRequestCount(requests, programs[j].id),
                        };
                        items.push(item);
                    }
                }
            }
            return items;
        },
    },
};
</script>
