<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета по ремонтам №2
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    REPAIRS_TYPES,
} from "@/store/constants";

export default {
    name: "DialogCreateRepairReport2",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
            counter: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            postTitle: "postTitle",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetName: "toolsSetName",
            getManufacturerName: "getManufacturerName",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    watch: {
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refRegionId.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refRegionId.hasError && !this.allRegions)) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            let repairReport = [];

            for (let i = 0; i < this.regions.length; i++) {
                if (this.allRegions) this.valueProgress = Math.round((i / this.regions.length) * 100);

                const currentRegionId = this.regions[i].id;
                if ((this.allRegions && currentRegionId < 90) || (!this.allRegions && currentRegionId === this.regionId)) {
                    const { requests } = (await this.$store.dispatch(
                        "getRequestsRepairReport",
                        {
                            region_id: currentRegionId,
                            date_beg: this.dateStart,
                            date_end: this.dateFinsh,
                        },
                    ));
                    const currentRepairReport = await this.getRepairReport2(requests, currentRegionId);
                    repairReport = repairReport.concat(currentRepairReport);
                }
            }

            const outFileName = `Отчет по ремонтам №2 за период с ${this.dateStart} по ${this.dateFinsh}.docx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                rr: repairReport,
            };

            createDoc(
                "repairreport-2.docx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveDoc(repairreport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRepairReport2(requests, currentRegionId) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                if (!this.allRegions) this.valueProgress = Math.round((i / requests.length) * 100);

                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    this.counter += 1;
                    const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                    const regionName = this.getRegionName(currentRegionId);
                    const orgName = this.getOrgProperty(orgId, "name");
                    const tool = await this.$store.dispatch(
                        "getSingleToolFromDB",
                        requests[i].tool_id,
                    );
                    const toolSetName = tool.set_id ? this.toolsSetName(tool.set_id) : "";
                    const toolKindName = this.getToolKindName(tool.tool_kind_id);
                    const toolModelName = `${this.getManufacturerName(tool.manufacturer_id)} ${this.getToolModelName(tool.model_id)}`;
                    const years = tool.work_in_dt
                        ? new Date(new Date() - new Date(tool.work_in_dt)).getFullYear() - 1970
                        : "";
                    const inventNumber = tool.inventar_number;
                    const serialNumber = tool.serial_number;
                    const co8Number = requests[i].co_8_number || "";
                    const request_date = new Date(requests[i].request_date_add);
                    const co_8_date = requests[i].co_8_date
                        ? date.extractDate(requests[i].co_8_date, "YYYY-MM-DD")
                        : 0;
                    let repairDays = requests[i].co_8_date
                        ? Math.round((co_8_date.getTime() - request_date.getTime()) / (1000 * 3600 * 24))
                        : "";
                    if (repairDays === -1 || repairDays === 0) {
                        repairDays = 1;
                    }
                    const costWork = requests[i].cost_work || ""; // this DB field is not used in current ISIAC version
                    const costSpares = requests[i].cost_spares || ""; // this DB field is not used in current ISIAC version
                    const repairType = REPAIRS_TYPES
                        .find((rt) => rt.value === requests[i].repair_type_id);
                    const repairTypeLabel = repairType ? repairType.label : "";

                    const item = {
                        n: this.counter,
                        region_name: regionName,
                        org_name: orgName,
                        tool_set_name: toolSetName,
                        tool_kind_name: toolKindName,
                        tool_model_name: toolModelName,
                        lifetime: years,
                        invent_number: inventNumber,
                        serial_number: serialNumber,
                        co_8_number: co8Number,
                        repair_days: repairDays,
                        cost_work: costWork,
                        cost_spares: costSpares,
                        repair_type: repairTypeLabel,
                    };
                    items.push(item);
                }
            }
            return items;
        },
    },
};
</script>
