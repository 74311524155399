<template>
    <q-btn
        fab
        :icon="icon || matAdd"
        color="primary"
        @click="() => $emit('start-edit')"
    >
        <q-tooltip
            :delay="300"
            anchor="top middle"
            self="bottom middle"
            class="bg-amber text-body2 text-black shadow-4"
        >
            {{ buttonToolTip }}
        </q-tooltip>
    </q-btn>
</template>

<script>
export default {
    name: "ButtonNewItem",
    props: {
        icon: {
            type: String,
            default: "",
        },
        buttonToolTip: {
            type: String,
            default: "",
        },
    },
    emits: ["start-edit"],
};
</script>
