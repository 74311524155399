/* eslint-disable */
var CADESCOM_CADES_BES = 1;
var CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;
var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
var CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
var CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
var CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
var CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION = 2;
var CADESCOM_BASE64_TO_BINARY = 1;

function ConvertDate(date) {
    switch (navigator.appName) {
        case "Microsoft Internet Explorer":
            return date.getVarDate();
        default:
            return date;
    }
}

function SignCreate0(certSubjectName, dataToSign) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            );

            var oStoreCerts = yield oStore.Certificates;
            var oCertificates = yield oStoreCerts.Find(
                CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, certSubjectName);
            var certsCount = yield oCertificates.Count;
            if (certsCount === 0) {
                const err = "Certificate not found: " + certSubjectName;
                alert(err);
                args[1](err);
            }
            var oCertificate = yield oCertificates.Item(1);
            var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);
            // const tspService = "http://www.cryptopro.ru/tsp/tsp.srf";
            const tspService = "http://testca.cryptopro.ru/tsp/tsp.srf";

            // "http://cryptopro.ru/tsp/"
            yield oSigner.propset_TSAAddress(tspService);

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_Content(dataToSign);

            try {
                var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_X_LONG_TYPE_1);
            }
            catch (e) {
                const err = cadesplugin.getLastError(e);
                alert("Failed to create signature. Error: " + err);
                args[1](err);
            }
            yield oStore.Close();
            return args[0](sSignedMessage);
        }, resolve, reject);
    });
}

function SignCreate(certSubjectName, dataToSign) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            );

            var oStoreCerts = yield oStore.Certificates;
            var oCertificates = yield oStoreCerts.Find(
                CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, certSubjectName);
            var certsCount = yield oCertificates.Count;
            if (certsCount === 0) {
                err = "Certificate not found: " + certSubjectName;
                alert(err);
                args[1](err);
            }
            var oCertificate = yield oCertificates.Item(1);

            var oSigningTimeAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
            yield oSigningTimeAttr.propset_Name(CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
            var oTimeNow = new Date();
            yield oSigningTimeAttr.propset_Value(ConvertDate(oTimeNow));

            var oDocumentNameAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
            yield oDocumentNameAttr.propset_Name(CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
            yield oDocumentNameAttr.propset_Value("Document Name");

            var oSignerNameAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
            yield oSignerNameAttr.propset_Name(CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION);
            yield oSignerNameAttr.propset_Value("SIGNER NAME");

            var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);

            var oAuthAttrs = yield oSigner.AuthenticatedAttributes2;
            yield oAuthAttrs.Add(oSigningTimeAttr);
            yield oAuthAttrs.Add(oDocumentNameAttr);
            yield oAuthAttrs.Add(oSignerNameAttr);

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_Content(dataToSign);

            try {
                var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES);
            }
            catch (e) {
                err = cadesplugin.getLastError(e);
                alert("Failed to create signature. Error: " + err);
                args[1](err);
            }
            yield oStore.Close();
            return args[0](sSignedMessage);
        }, resolve, reject);
    });
}

function Verify1(sSignedMessage) {
    return new Promise(
        function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (args) {
                    var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    try {
                        yield oSignedData.VerifyCades(sSignedMessage, CADESCOM_CADES_BES);
                    }
                    catch (e) {
                        const err = cadesplugin.getLastError(e);
                        alert("Failed to verify signature. Error: " + err);
                        return args[1](err);
                    }

                    var oSigners = yield oSignedData.Signers;
                    var oSigner = yield oSigners.Item(1);
                    console.log(oSigner);
                    var signingTime = yield oSigner.SigningTime;
                    var docName = yield oSigner.DocumentName;
                    var authorName = yield oSigner.AuthenticatedAttributes2;
                    console.log(docName, authorName);
                    // var SigningTime = new Date(signingTime);
                    alert(signingTime + docName + authorName);
                    return args[0](true);
                },
                resolve,
                reject,
            );
        });
}

function signBlob(certSubjectName, oFile) {
    return new Promise((resolve, reject) => {
        var oFReader = new FileReader();

        if (typeof (oFReader.readAsDataURL) != "function") {
            reject("Method readAsDataURL() is not supported in FileReader.");
            return;
        }

        oFReader.readAsDataURL(oFile);

        oFReader.onload = function (oFREvent) {
            // const oStore2 = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            // console.log(oStore2);

            // reject("GGGG");

            // if (certSubjectName) {
            //     return;
            // }

            cadesplugin.async_spawn(function* (args) {
                var header = ";base64,";
                var sFileData = oFREvent.target.result;
                var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

                var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");

                yield oStore.Open(
                    CAPICOM_CURRENT_USER_STORE,
                    CAPICOM_MY_STORE,
                    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                );

                var oStoreCerts = yield oStore.Certificates;
                var oCertificates = yield oStoreCerts.Find(
                    CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME,
                    certSubjectName);
                var certsCount = yield oCertificates.Count;

                if (certsCount === 0) {
                    reject("Certificate not found: " + certSubjectName);
                    return;
                }

                var oCertificate = yield oCertificates.Item(1);
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                yield oSigner.propset_Certificate(oCertificate);
                yield oSigner.propset_CheckCertificate(true);

                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                yield oSignedData.propset_Content(sBase64Data);

                let sSignedMessage = "";

                try {
                    sSignedMessage = yield oSignedData.SignCades(
                        oSigner,
                        CADESCOM_CADES_BES,
                        true);
                }
                catch (err) {
                    reject("Failed to create signature. Error: " + cadesplugin.getLastError(err));
                    return;
                }

                yield oStore.Close();

                resolve(sSignedMessage);

                // Выводим отделенную подпись в BASE64 на страницу
                // Такая подпись должна проверяться в КриптоАРМ и cryptcp.exe
                /*
                document.getElementById("signature").innerHTML = sSignedMessage;
    
                var oSignedData2 = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                try {
                    yield oSignedData2.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData2.propset_Content(sBase64Data);
                    yield oSignedData2.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);
                    alert("Signature verified");
                }
                catch (err) {
                    alert("Failed to verify signature. Error: " + cadesplugin.getLastError(err));
                    return;
                }
                */
            });
        };
    });
}

function Verify(sSignedMessage, dataInBase64) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(
            function* (args) {
                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                try {
                    // Значение свойства ContentEncoding должно быть задано
                    // до заполнения свойства Content
                    yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData.propset_Content(dataInBase64);
                    yield oSignedData.VerifyCades(sSignedMessage, cadesplugin.CADESCOM_CADES_BES, true);
                }
                catch (err) {
                    var e = cadesplugin.getLastError(err);
                    alert("Failed to verify signature. Error: " + e);
                    return args[1](e);
                }
                return args[0]();
            },
            resolve,
            reject,
        );
    });
}

function run() {
    var oCertName = document.getElementById("CertName");
    var sCertName = oCertName.value; // Здесь следует заполнить SubjectName сертификата
    if ("" === sCertName) {
        alert("Введите имя сертификата (CN).");
        return;
    }

    SignCreate(sCertName, "Message")
        .then(
            function (signedMessage) {
                document.getElementById("signature").innerHTML = signedMessage;
                Verify(signedMessage).then(
                    function () {
                        alert("Signature verified");
                    },
                    function (err) {
                        document.getElementById("signature").innerHTML = err;
                    });
            },
            function (err) {
                document.getElementById("signature").innerHTML = err;
            }
        );
}

function testCades() {
    console.log(cadesplugin);
}

export default {
    SignCreate,
    Verify,
    signBlob,
    testCades,
};
