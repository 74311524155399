<template>
    <div
        class="row-wrapper"
        @click="$emit('editItem', index)"
    >
        <div class="cell cell-center">
            {{ (+currentPage - 1) * 20 + index + 1 }}
        </div>
        <div class="cell cell-center">
            {{ regionId }}
        </div>
        <div class="cell">
            {{ unitName }}
        </div>
        <div class="cell">
            {{ toolsSetName(toolsSet.type_id) }}
        </div>
        <div class="cell cell-column tools-sets-list">
            <div
                v-for="(c, i) in contents"
                :key="i"
                class="cell"
            >
                {{ c }}
            </div>
        </div>
        <div class="cell">
            {{ toolsSet.inventar_number }}
        </div>
        <div class="cell cell-center">
            {{ dateYMDtoDMY(toolsSet.work_in_dt) }}
        </div>
        <div class="cell cell-center">
            {{ dateDiffYMD(toolsSet.work_in_dt) }}
        </div>
        <div class="cell cell-center">
            <q-btn
                round
                :icon="matContentCopy"
                size="sm"
                @click="createCopy"
            >
                <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :delay="300"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Создать копию
                </q-tooltip>
            </q-btn>
        </div>
        <div class="cell cell-center">
            <q-btn
                round
                :icon="matDelete"
                size="0.7rem"
                @click="deleteToolsSet"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateYMDtoDMY, dateDiffYMD } from "@/helpers/helpers";

export default {
    name: "ToolsSetRow",
    props: {
        index: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        toolsSet: {
            type: Object,
            required: true,
        },
    },
    emits: ["editItem"],
    setup() {
        return {
            dateYMDtoDMY,
            dateDiffYMD,
        };
    },
    computed: {
        ...mapGetters({
            toolsSetName: "toolsSetName",
            unitsFull: "unitsFull",
        }),
        regionId() {
            const unit = this.unitsFull.find((u) => u.id === this.toolsSet.unit_id);

            return unit ? unit.region_id : "???";
        },
        unitName() {
            const unit = this.unitsFull.find((u) => u.id === this.toolsSet.unit_id);
            // console.log(unit);

            return unit ? unit.fullname : "???";
        },
        contents() {
            return this.toolsSet.contents.split(",");
        },
    },
    methods: {
        createCopy(e) {
            e.stopPropagation();
            this.$emit("editItem", this.index, true);
        },
        deleteToolsSet(e) {
            e.stopPropagation();
        },
    },
};
</script>
