<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Характеристики видов учетных единиц
            ({{ traits.length }})
        </div>
        <div class="content-body tools-kinds-traits-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Наименование характеристики
            </div>
            <div class="cell cell-header cell-center">
                Количество значений
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="startEdit(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(trt, index) in traits"
                :key="index"
                class="row-wrapper"
                @click="startEdit(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ trt.title }}
                </div>
                <div class="cell cell-center">
                    {{ trt.values.length }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import DialogEditToolKindTrait from "@/components/Dialogs/DialogEditToolKind/DialogEditToolKindTrait.vue";

export default {
    name: "ToolsKindsTraits",
    computed: {
        ...mapGetters({
            traits: "traits",
        }),
    },
    methods: {
        startEdit(index) {
            this.$q.dialog({
                component: DialogEditToolKindTrait,
                componentProps: {
                    traitOriginal: index === null
                        ? {
                            id: null,
                            title: "",
                            values: [],
                        }
                        : JSON.parse(JSON.stringify(this.traits[index])),
                },
            })
                .onOk((trait) => {
                    this.$store.dispatch("saveToolKindTrait", trait)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        })
                        .finally(() => {
                            this.$store.dispatch("getToolsKindsTraitsFromDB");
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить параметр "${this.traits[index].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteToolKindTrait", this.traits[index].id)
                        .then((message) => {
                            this.$store.dispatch("getToolsKindsTraitsFromDB");
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
