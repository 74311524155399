import XlsxPopulate from "xlsx-populate";
import { siteHost } from "../store/constants";
import { cellVS2, saveFile } from "./helpers";

export function saveModelsToXls(
    manufacturers,
    models,
) {
    console.log("SMXLS");

    const url = `${siteHost}/public/templates/manufacturers-models-list.xlsx`;

    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onreadystatechange = function r() {
        if (req.readyState === 4 && req.status === 200) {
            XlsxPopulate.fromDataAsync(req.response)
                .then(async (workbook) => {
                    for (let iM = 0; iM < models.length; iM++) {
                        const m = models[iM];

                        const cA = workbook.sheet(0).cell(`A${iM + 5}`);
                        cellVS2({ cell: cA, value: `${iM + 1}` });
                        const cB = workbook.sheet(0).cell(`B${iM + 5}`);
                        cellVS2({ cell: cB, value: m.toolKindName });
                        const cC = workbook.sheet(0).cell(`C${iM + 5}`);
                        cellVS2({ cell: cC, value: m.manufacturerName });
                        const cD = workbook.sheet(0).cell(`D${iM + 5}`);
                        cellVS2({ cell: cD, value: m.model });
                    }

                    for (let iM = 0; iM < manufacturers.length; iM++) {
                        const m = manufacturers[iM];

                        const cA = workbook.sheet(1).cell(`A${iM + 5}`);
                        cellVS2({ cell: cA, value: `${iM + 1}` });
                        const cB = workbook.sheet(1).cell(`B${iM + 5}`);
                        cellVS2({ cell: cB, value: m.title });
                    }

                    // Get the output
                    return workbook.outputAsync("blob");
                })
                .then((blob) => {
                    const fileName = "Модели_производители.xlsx";
                    saveFile(fileName, blob);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    req.send();
}
