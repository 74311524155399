import axios from "axios";
import moment from "@/helpers/moment";
import {
    REQUEST_KINDS,
    REQUEST_STATUSES,
    siteHost,
    RIGHTS_CONSTANTS,
    PERFORM_KIND,
    CONTACT_KIND,
    REQUEST_STATUS_VALUES,
} from "@/store/constants";

export const cleanFilterState = {
    date_beg: `01.01.${(new Date()).getFullYear()}`,
    date_end: `31.12.${(new Date()).getFullYear()}`,
    request_kinds_ids: [],
    request_statuses_ids: [],
    units_ids: [],
    request_performer_kind: null,
    unsigned_docs: false,
};

function saveRequestToDB(
    currentUser,
    request,
    requestEngineers,
    reqWorks,
    reqSpares,
    filesList,
    requestDocs,
) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append(
            "request",
            JSON.stringify({
                ...request,
                currentUserId: currentUser.id,
            }),
        );
        formData.append("engineers", JSON.stringify(requestEngineers));
        formData.append("works", JSON.stringify(reqWorks));
        formData.append("spares", JSON.stringify(reqSpares));

        formData.append("section", "request");

        formData.append(
            "filesList",
            JSON.stringify(
                filesList
                    .map((file) => ({ path: file.path }))
                    .filter((e) => e.path),
            ),
        );

        filesList.forEach((file) => {
            if (file.blob) {
                formData.append("request", file.blob);
            }
        });

        formData.append("section", "requestDocs");
        // list of old files
        formData.append(
            "oldDocsList",
            JSON.stringify(
                requestDocs
                    .map((file) => ({ path: file.path }))
                    .filter((e) => e.path),
            ),
        );
        // files themselves
        const newDocsList = [];
        requestDocs.forEach((file) => {
            if (file.blob) {
                formData.append("requestDocs", file.blob);
                newDocsList.push(file);
            }
        });
        formData.append("newDocsList", JSON.stringify(newDocsList));

        const method = request.id ? "put" : "post";

        axios[method](
            `${siteHost}/api/request`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default {
    state: {
        requests: [],
        filterRequests: cleanFilterState,

        filterRequestsRepair: {},
        filterRequestsRepairTool: {},
        filterRequestsLearning: {},
        filterRequestsSoft: {},
        reqsCount: 0,

        request: {},
        filesList: [],
        requestEngineers: [],
        requestHistory: [],

        requestIsLoading: false,
    },
    // =========================================================================
    getters: {
        requests: (state) => state.requests,
        getReqKindTitle: () => (reqKindId) => {
            const reqKind = REQUEST_KINDS.find((rk) => rk.id === reqKindId);
            return reqKind
                ? reqKind.title
                : "Неизвестный тип";
        },
        getReqStatusTitle: () => (reqStatusId) => {
            const reqStatus = REQUEST_STATUSES.find((rs) => rs.id === reqStatusId);
            return reqStatus
                ? reqStatus.title
                : `!!! Неизвестный статус (${reqStatusId}) !!!`;
        },
        filterRequests: (state) => state.filterRequests,
        filterRequestsRepair: (state) => state.filterRequestsRepair,
        filterRequestsRepairTool: (state) => state.filterRequestsRepairTool,
        filterRequestsLearning: (state) => state.filterRequestsLearning,
        filterRequestsSoft: (state) => state.filterRequestsSoft,
        reqsCount: (state) => state.reqsCount,

        request: (state) => state.request,
        filesList: (state) => state.filesList,
        requestEngineers: (state) => state.requestEngineers,
        requestHistory: (state) => state.requestHistory,

        requestIsLoading: (state) => state.requestIsLoading,
    },
    // =========================================================================
    actions: {
        //----------------------------------------------------------------------
        // get requests from DB
        getRequestsFromDB({ commit, state, getters }) {
            return new Promise((resolve, reject) => {
                // if can't read - return
                if (!(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.OWN) &&
                    !(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)) {
                    return;
                }

                let filterL = {
                    ...state.filterRequests,
                    pageNumber: getters.reqsPageNumber,
                    currentUserId: getters.currentUser.id,
                };
                if (!(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)) {
                    filterL = {
                        ...filterL,
                        units: getters.currentUser.units,
                    };
                }

                commit("setMainSpinnerState", true);
                axios.get(
                    `${siteHost}/api/request`,
                    {
                        params: {
                            filter: filterL,
                            filterRequestsRepair: getters.filterRequestsRepair,
                            filterRequestsRepairTool: getters.filterRequestsRepairTool,
                            filterRequestsLearning: getters.filterRequestsLearning,
                            filterRequestsSoft: getters.filterRequestsSoft,
                        },
                    },
                )
                    .then((res) => {
                        commit("setRequests", res.data.requests);
                        commit("setReqsPagesCount", res.data.pagesCount);
                        commit("setReqsCount", res.data.itemsCount);

                        if (getters.reqsPageNumber > res.data.pagesCount) {
                            commit("setReqsPageNumber", 1);
                        }

                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        commit("setMainSpinnerState", false);
                    });
            });
        },
        async getAllRequestsFromDB({ state, getters }) {
            let filterL = {
                ...state.filterRequests,
                pageNumber: 1,
                currentUserId: getters.currentUser.id,
                allRequests: true,
            };

            if (!(getters.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)) {
                filterL = {
                    ...filterL,
                    units: getters.currentUser.units,
                };
            }

            const res = await axios.get(
                `${siteHost}/api/request`,
                {
                    params: {
                        filter: filterL,
                        filterRequestsRepair: getters.filterRequestsRepair,
                        filterRequestsRepairTool: getters.filterRequestsRepairTool,
                        filterRequestsLearning: getters.filterRequestsLearning,
                        filterRequestsSoft: getters.filterRequestsSoft,
                    },
                },
            );

            return res.data.requests;
        },
        setFilterRequests({ commit, dispatch }, filter) {
            return new Promise((resolve) => {
                commit("setFilterRequests", filter);
                commit("setReqsPageNumber", 1);
                dispatch("getRequestsFromDB");

                resolve();
            });
        },
        setFilterRequestsRepairTool({ commit, dispatch }, filter) {
            return new Promise((resolve) => {
                commit("setFilterRequestsRepairTool", filter);
                commit("setReqsPageNumber", 1);
                dispatch("getRequestsFromDB");

                resolve();
            });
        },
        cleanReqsFilter({ commit, dispatch }) {
            return new Promise((resolve) => {
                commit("setFilterRequestsRepair", {});
                commit("setFilterRequestsRepairTool", {});
                commit("setFilterRequestsLearning", {});
                commit("setFilterRequestsSoft", {});
                dispatch(
                    "setFilterRequests",
                    cleanFilterState,
                )
                    .then(() => {
                        resolve();
                    });
            });
        },
        setFilterRequestsRepair({ commit, dispatch }, payload) {
            commit("setFilterRequestsRepair", payload);
            commit("setReqsPageNumber", 1);
            dispatch("getRequestsFromDB");
        },
        setFilterRequestsLearning({ commit, dispatch }, payload) {
            commit("setFilterRequestsLearning", payload);
            commit("setReqsPageNumber", 1);
            dispatch("getRequestsFromDB");
        },
        setFilterRequestsSoft({ commit, dispatch }, payload) {
            commit("setFilterRequestsSoft", payload);
            commit("setReqsPageNumber", 1);
            dispatch("getRequestsFromDB");
        },
        getFilesListFromDB(context, { section, id }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_files/${section}/${id}`)
                    .then((res) => {
                        resolve(res.data.files);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRequestFile({ commit }, file) {
            commit("addRequestFile", file);
        },
        delRequestFile({ commit }, index) {
            commit("delRequestFile", index);
        },
        getRequestEngineers({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_engineers/${reqId}`)
                    .then((res) => {
                        commit("setRequestEngineers", res.data.engineers);
                        resolve(res.data.engineers);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // addRequestEngineer({ commit }, id) {
        //     commit("addRequestEngineer", id);
        // },
        // delRequestEngineer({ commit }, index) {
        //     commit("delRequestEngineer", index);
        // },
        setRequestEngineers({ commit }, engineersIds) {
            commit("setRequestEngineers", engineersIds);
        },
        getRequestHistory({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_history/${reqId}`)
                    .then((res) => {
                        commit("setRequestHistory", res.data.request_history);
                        resolve(res.data.request_history);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //----------------------------------------------------------------------
        // working instance
        setRequestField({ commit }, payload) {
            return new Promise((resolve) => {
                commit("setRequestField", payload);
                resolve();
            });
        },
        setRequest({ commit, dispatch }, request) {
            return new Promise((resolve) => {
                commit(
                    "setRequest",
                    {
                        request_date_add: moment().format("YYYY-MM-DD HH:mm:ss"),
                        request_status_id: REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                        request_performer_kind: PERFORM_KIND.FILIAL,
                        contact_kind: CONTACT_KIND.FROM_IAC,
                        ...request,
                    },
                );

                if (request.id) {
                    commit("setRequestLoading", true);

                    const p1 = dispatch(
                        "getFilesListFromDB",
                        {
                            section: "request",
                            id: request.id,
                        },
                    );
                    const p2 = dispatch("getRequestDocs", request.id);
                    const p3 = dispatch("getRequestWorks", request.id);
                    const p4 = dispatch("getRequestSpares", request.id);
                    const p5 = dispatch("getRequestEngineers", request.id);
                    const p6 = dispatch("getRequestHistory", request.id);
                    const p7 = dispatch("getRequestComments", request.id);

                    Promise
                        .all([p1, p2, p3, p4, p5, p6, p7])
                        .then((values) => {
                            commit("setRequestFilesList", values[0]);
                        })
                        .finally(() => {
                            commit("setRequestLoading", false);
                        });
                }
                else {
                    // new request
                    commit("setRequestFilesList", []);
                    commit("setRequestDocs", []);
                    commit("setRequestWorks", []);
                    commit("setRequestSpares", []);
                    commit("setRequestEngineers", []);
                    commit("setRequestHistory", []);
                    commit("setRequestComments", []);
                }
                resolve();
            });
        },
        //----------------------------------------------------------------------
        // save
        saveRequest(
            {
                state,
                commit,
                dispatch,
                getters,
            },
            resetCurrentRequest,
        ) {
            if (!state.request.id) {
                const engineersOfUnit = getters.engineersOfUnit(state.request.unit_id);
                if (engineersOfUnit.length) {
                    commit("setRequestEngineers", [engineersOfUnit[0].user_id]);
                }
            }

            return new Promise((resolve, reject) => {
                saveRequestToDB(
                    getters.currentUser,
                    state.request,
                    getters.requestEngineers,
                    getters.reqWorks,
                    getters.reqSpares,
                    getters.filesList,
                    getters.requestDocs,
                )
                    .then(async (responseData) => {
                        await dispatch("getRequestsFromDB");
                        await dispatch("getSparesSetsItemsFromDB");

                        if (!state.request.id) {
                            await dispatch("setRequest", {});
                        }
                        else if (resetCurrentRequest) {
                            await dispatch("setRequest", responseData.savedRequest);
                        }

                        resolve(responseData.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async saveMultipleRequests(
            { commit, dispatch, getters },
            {
                // requestsDate,
                requestText,
                unitsIds,
                filesList,
            },
        ) {
            commit("setMainSpinnerState", true);

            for (let i = 0; i < unitsIds.length; i++) {
                const engineersOfUnit = getters.engineersOfUnit(unitsIds[i]);
                const requestEngineers = engineersOfUnit.length
                    ? [engineersOfUnit[0].user_id]
                    : [];

                await saveRequestToDB(
                    getters.currentUser,
                    {
                        // request_date_add: requestDateAdd,
                        request_status_id: REQUEST_STATUS_VALUES.WAITING_ENGINEER,
                        request_performer_kind: PERFORM_KIND.FILIAL,
                        contact_kind: CONTACT_KIND.STRANGER,
                        contact_unknown_name: [
                            getters.currentUser.surname,
                            getters.currentUser.name,
                            getters.currentUser.patronymic,
                        ].join(" "),
                        contact_unknown_email: getters.currentUser.email,
                        request_text: requestText,
                        unit_id: unitsIds[i],
                        contact_id: null,
                        tool_id: null,
                    },
                    requestEngineers,
                    [], // getters.reqWorks,
                    [], // getters.reqSpares,
                    filesList,
                    [], // getters.requestDocs,
                );
            }

            dispatch("getRequestsFromDB");

            commit("setMainSpinnerState", false);
        },
        deleteRequest({ dispatch }, requestId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/request/${requestId}`)
                    .then((response) => {
                        dispatch("getRequestsFromDB");
                        resolve(response.data.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        /*
        //----------------------------------------------------------------------
        registerInCia({ getters }, reqId) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_register_in_cia`,
                    {
                        reqId,
                        currentUserId: getters.currentUser.id,
                    },
                )
                    .then((res) => {
                        resolve(res.data.message);
                    })
                    .catch((err) => {
                        reject(err.response.data.message);
                    });
            });
        },
        //----------------------------------------------------------------------
        closeInCia({ getters }, reqId) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_close_in_cia`,
                    {
                        reqId,
                    },
                )
                    .then((res) => {
                        resolve(res.data.message);
                    })
                    .catch((err) => {
                        reject(err.response.data.message);
                    });
            });
        },
        */
        //----------------------------------------------------------------------
        getRequestsFormular(
            context,
            { unitId, date_beg = "01.01.2022", date_end = "31.12.2022" },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    unit_id: unitId,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_formular/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsEnclosure1(
            context,
            {
                unitId,
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    unit_id: unitId,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_enclosure_1/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsEnclosure2(
            context,
            {
                unitId,
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    unit_id: unitId,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_enclosure_2/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsForArchive(
            context,
            { region_id, date_beg = "01.01.2022", date_end = "31.12.2022" },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    region_id,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_for_archive/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsRepairReport(
            context,
            {
                region_id,
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    region_id,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/repair_report/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsRepairAllRegions(
            context,
            {
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_all/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsSoftAllRegions(
            context,
            {
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_soft_all/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getRequestsSoft(
            context,
            {
                region_id,
                date_beg = `01.01.${(new Date()).getFullYear()}`,
                date_end = `31.12.${(new Date()).getFullYear()}`,
            },
        ) {
            return new Promise((resolve, reject) => {
                const filter = {
                    region_id,
                    date_beg,
                    date_end,
                };

                axios.get(`${siteHost}/api/requests_soft/${JSON.stringify(filter)}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        hotLineRegister(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_hot_line_register`,
                    payload,
                    {
                        onDownloadProgress: (progressEvent) => {
                            console.log(progressEvent.currentTarget.response);
                        },
                    },
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        hotLineClose(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_hot_line_close`,
                    payload,
                )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        hotLineEmailRegister(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_hot_line_email_register`,
                    payload,
                )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        hotLineEmailClose(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/request_hot_line_email_close`,
                    payload,
                )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRequestLoading({ commit }, v) {
            return new Promise((resolve) => {
                commit("setRequestLoading", v);
                resolve();
            });
        },
    },
    // =========================================================================
    mutations: {
        setRequests(state, requests) {
            state.requests = requests;
        },
        setFilterRequests(state, filter) {
            state.filterRequests = filter;
        },
        setFilterRequestsRepair(state, filterRequestsRepair) {
            state.filterRequestsRepair = filterRequestsRepair;
        },
        setFilterRequestsRepairTool(state, filterRequestsRepairTool) {
            state.filterRequestsRepairTool = filterRequestsRepairTool;
        },
        setFilterRequestsLearning(state, filterRequestsLearning) {
            state.filterRequestsLearning = filterRequestsLearning;
        },
        setFilterRequestsSoft(state, filterRequestsSoft) {
            state.filterRequestsSoft = filterRequestsSoft;
        },
        //----------------------------------------------------------------------
        setRequestField(state, { field, value }) {
            state.request = {
                ...state.request,
                [field]: value,
            };
        },
        setRequest(state, request) {
            state.request = request;
        },
        setReqsCount(state, reqsCount) {
            state.reqsCount = reqsCount;
        },
        setRequestFilesList(state, filesList) {
            state.filesList = filesList;
        },
        addRequestFile(state, file) {
            state.filesList.push(file);
        },
        delRequestFile(state, index) {
            state.filesList.splice(index, 1);
        },
        setRequestEngineers(state, requestEngineers) {
            state.requestEngineers = requestEngineers;
        },
        // addRequestEngineer(state, id) {
        //     if (!state.requestEngineers.includes(id)) {
        //         state.requestEngineers.push(id);
        //     }
        // },
        // delRequestEngineer(state, index) {
        //     state.requestEngineers.splice(index, 1);
        // },
        setRequestHistory(state, requestHistory) {
            state.requestHistory = requestHistory;
        },
        setRequestLoading(state, v) {
            state.requestIsLoading = v;
        },
    },
};
