<template>
    <q-icon
        v-if="docSign.path"
        :name="matCheckCircleOutline"
        size="1.5rem"
        color="secondary"
    >
        <q-tooltip
            :delay="300"
            anchor="top right"
            self="bottom middle"
            class="bg-amber text-body2 text-black shadow-4"
        >
            Подписан
            {{ getUserFIO(signerId) }}
            {{ dateYMDtoDMY(docSign.date_time) }}
        </q-tooltip>
    </q-icon>
    <q-icon
        v-else
        :name="matHelpOutline"
        size="1.5rem"
        color="grey"
    >
        <q-tooltip
            :delay="300"
            anchor="top right"
            self="bottom middle"
            class="bg-amber text-body2 text-black shadow-4"
        >
            Не подписан
            {{ getUserFIO(signerId) }}
        </q-tooltip>
    </q-icon>
</template>

<script>
import { mapGetters } from "vuex";
import { dateYMDtoDMY } from "@/helpers/helpers";

export default {
    name: "DocumentsRowSignIcon",
    props: {
        docSign: {
            type: Object,
            required: true,
        },
        signerId: {
            type: Number,
            required: true,
        },
    },
    setup() {
        return {
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            getUserFIO: "getUserFIO",
        }),
    },
};
</script>
