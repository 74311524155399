<template>
    <q-input
        v-model="commentComment"
        outlined
        dense
        autogrow
        label="Новое сообщение"
        hideBottomSpace
        :rules="[val => val.length < 2000 || 'Не более 2000 символов']"
    />
    <div class="comment-files-grid">
        <div class="cell">
            <div
                v-for="(file, index) in commentFiles"
                :key="index"
            >
                {{ file.file_name }}
                <q-btn
                    :icon="matDelete"
                    round
                    size="0.5rem"
                    class="q-ma-sm"
                    @click="removeFile(index)"
                />
            </div>
        </div>
        <div class="cell">
            <FilesList
                v-model:filesList="commentFiles"
                fileSection="requestCommentFiles"
                :maxFileSize="10485760"
            />
        </div>
        <div class="cell">
            <q-btn
                round
                :icon="matSend"
                size="sm"
                :disable="commentComment.length < 5"
                @click="addComment"
            >
                <q-tooltip
                    :delay="300"
                    anchor="top right"
                    self="bottom middle"
                    class="bg-amber text-body2 text-black shadow-4"
                >
                    Отправить комментарий
                </q-tooltip>
            </q-btn>
        </div>
    </div>

    <div class="request-comments-grid">
        <!-- header -->
        <div class="cell cell-header">
            №
        </div>
        <div class="cell cell-header">
            Дата
        </div>
        <div class="cell cell-header">
            Пользователь
        </div>
        <div class="cell cell-header">
            Сообщение
        </div>
        <!-- data -->
        <div
            v-for="(comment, index) in requestComments"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell">
                {{ index + 1 }}
            </div>
            <div class="cell">
                {{ date.formatDate(comment.date_time_d, "DD.MM.YYYY HH:mm") }}
            </div>
            <div class="cell">
                {{ getUserPostTitle(comment.user_id) }}
                {{ getUserFIO(comment.user_id) }}
            </div>
            <div class="cell request-comment--comment">
                <q-input
                    :modelValue="comment.comment"
                    borderless
                    dense
                    autogrow
                    class="q-mb-sm"
                    readonly
                />
                <div
                    v-for="file, indexF in comment.files"
                    :key="`${comment.id}_${indexF}`"
                    class="request-comment--file"
                >
                    {{ file.file_name }}
                    <q-btn
                        v-if="file.path"
                        round
                        :icon="matDownload"
                        size="0.5rem"
                        @click="() => saveDocAsFile(`${siteHost}/${file.path}`, file.file_name)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import { saveAs } from "file-saver";
import FilesList from "@/components/FilesList.vue";

export default {
    name: "DialogEditRequestComments",
    components: {
        FilesList,
    },
    props: {
        requestId: {
            type: Number,
            required: true,
        },
    },
    setup() {
        return {
            date,
        };
    },
    data() {
        return {
            commentComment: "",
            commentFiles: [],
        };
    },
    computed: {
        ...mapGetters({
            requestComments: "requestComments",
            getUserPostTitle: "getUserPostTitle",
            getUserFIO: "getUserFIO",
        }),
    },
    methods: {
        addComment() {
            this.$store.dispatch(
                "addRequestComment",
                {
                    request_id: this.requestId,
                    comment: this.commentComment,
                    files: this.commentFiles,
                },
            );
            this.commentComment = "";
            this.commentFiles = [];
        },
        saveDocAsFile(url, fileName) {
            saveAs(url, fileName);
        },
        removeFile(index) {
            this.commentFiles.splice(index, 1);
        },
    },
};
</script>
