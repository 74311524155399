<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Подписать документ
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-select
                    v-model="certificateObj"
                    outlined
                    dense
                    :options="certificates"
                    optionValue="name"
                    mapOptions
                    optionLabel="fullName"
                    label="Выберите сертификат"
                    class="q-mb-sm"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.name }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ scope.opt.issuerName }} до {{ scope.opt.validTo }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <div
                    v-if="generatedSign"
                    class="doc-sign"
                >
                    Подпись создана
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <!-- <q-btn
                    flat
                    label="test save sign"
                    color="primary"
                    :icon="matCreate"
                    :disable="!certificateObj"
                    @click="onOkClick"
                /> -->
                <q-btn
                    v-if="!generatedSign"
                    flat
                    label="Подписать"
                    color="primary"
                    :icon="matCreate"
                    :disable="!certificateObj"
                    @click="doSign"
                />
                <q-btn
                    v-else
                    flat
                    label="Сохранить подпись"
                    color="primary"
                    :icon="matCreate"
                    :disable="!certificateObj"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Выйти"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { getUserCertificates } from "crypto-pro";
import { date } from "quasar";
import cadesHelper from "@/cadesplugin/cadeshelper";

export default {
    name: "DialogSign",
    props: {
        dataForSign: {
            type: Blob,
            default: null,
        },
    },
    emits: ["ok"],
    data() {
        return {
            certificates: [],
            certificateObj: null,
            generatedSign: "",
        };
    },
    methods: {
        show() {
            // cadesHelper.testCades();
            getUserCertificates()
                .then((certificates) => {
                    this.certificates = certificates.map((c) => {
                        const issuerName = c.issuerName
                            .split(",")[0]
                            .split("=")[1];
                        return {
                            name: c.name,
                            validFrom: date.formatDate(new Date(c.validFrom).getTime(), "DD.MM.YYYY"),
                            validTo: date.formatDate(new Date(c.validTo).getTime(), "DD.MM.YYYY"),
                            issuerName,
                            fullName: `${c.name}
                                (${issuerName} до
                                ${date.formatDate(new Date(c.validTo).getTime(), "DD.MM.YYYY")})`,
                            thumbprint: c.thumbprint,
                        };
                    });
                    this.$refs.dialog.show();
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err.message,
                    });

                    this.$refs.dialog.hide();
                });
        },
        hide() {
            this.$refs.dialog.hide();
        },
        doSign() {
            cadesHelper.signBlob(
                this.certificateObj.name,
                this.dataForSign,
            )
                .then((generatedSign) => {
                    this.generatedSign = generatedSign;
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err,
                    });
                });
        },
        onOkClick() {
            // this.generatedSign = "AAAABBBBCCCCDDDD";
            this.$emit(
                "ok",
                {
                    generatedSign: this.generatedSign,
                    certificateObj: this.certificateObj,
                },
            );
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
