<template>
    <div class="tool-history-grid">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Дата время
        </div>
        <div class="cell cell-header cell-center">
            Пользователь
        </div>
        <div class="cell cell-header cell-center">
            Атрибут
        </div>
        <div class="cell cell-header cell-center">
            Старое значение
        </div>
        <div class="cell cell-header cell-center">
            Новое значение
        </div>
        <div
            v-for="(h, index) in toolHistoryFiltered"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ h.date_time_d }}
            </div>
            <div class="cell">
                {{ getUserFIO(h.user_id) }}
            </div>
            <div class="cell">
                {{ getAttrTitle(h.object_attr) }}
            </div>
            <div class="cell">
                {{ getValue(h.object_attr, h.value_old) }}
            </div>
            <div class="cell">
                {{ getValue(h.object_attr, h.value_new) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateYMDtoDMY } from "@/helpers/helpers";
import { TOOLS_STATES, TOOLS_OWNERS_TYPES } from "../../../store/constants";

const attrsTitles = {
    tool_kind_id: "Вид оборудования",
    manufacturer_id: "Производитель",
    unit_id: "Объект",
    model_id: "Модель",
    serial_number: "Серийный номер",
    inventar_number: "Инвентарный номер",
    description: "Комментарий",
    work_in_dt: "Дата ввода в эксплуатацию",
    work_out_dt: "Дата вывода из эксплуатации",
    guaranty_dt: "Дата окончания гарантийного срока",
    in_set: "Входит в комплект",
    state: "Состояние",
    set_id: "Комплект",
    owner_type_id: "Принадлежность",
    cost_remain: "Остаточная стоимость",
};

export default {
    name: "DialotEditToolHistory",
    props: {
        toolHistory: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            getUserFIO: "getUserFIO",
            getUnitTitle: "getUnitTitle",
            getManufacturerName: "getManufacturerName",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetName: "toolsSetName",
        }),
        toolHistoryFiltered() {
            return this.toolHistory.filter((e) => (
                ![
                    "tool_softwares",
                    "tool_places",
                    "tool_movings",
                    "tool_histories",
                    "address",
                ].includes(e.object_attr)
            ));
        },
    },
    methods: {
        getAttrTitle(attr) {
            return attrsTitles[attr] || attr;
        },
        getValue(attr, value) {
            if (attr === "unit_id") {
                return this.getUnitTitle(+value);
            }
            if (attr === "manufacturer_id") {
                return this.getManufacturerName(+value);
            }
            if (attr === "tool_kind_id") {
                return this.getToolKindName(+value);
            }
            if (attr === "model_id") {
                return this.getToolModelName(+value);
            }
            if (attr === "work_in_dt" || attr === "work_out_dt") {
                return dateYMDtoDMY(value);
            }
            if (attr === "set_id") {
                return this.toolsSetName(+value);
            }
            if (attr === "state") {
                const oState = TOOLS_STATES.find((s) => s.id === +value);

                return oState ? oState.title : "";
            }
            if (attr === "owner_type_id") {
                const oOwnerType = TOOLS_OWNERS_TYPES.find((o) => o.value === +value);

                return oOwnerType ? oOwnerType.label : "";
            }

            return value;
        },
    },
};
</script>
