<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета по ремонтам №3
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    REPAIRS_TYPES_VALUES,
    TOOLS_OWNERS_TYPES_VALUES,
} from "@/store/constants";

export default {
    name: "DialogCreateRepairReport3",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
            counter: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            postTitle: "postTitle",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetName: "toolsSetName",
            getManufacturerName: "getManufacturerName",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    watch: {
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refRegionId.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refRegionId.hasError && !this.allRegions)) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            let repairReport = [];

            for (let i = 0; i < this.regions.length; i++) {
                if (this.allRegions) this.valueProgress = Math.round((i / this.regions.length) * 100);

                const currentRegionId = this.regions[i].id;
                if ((this.allRegions && currentRegionId < 90) || (!this.allRegions && currentRegionId === this.regionId)) {
                    const { requests } = (await this.$store.dispatch(
                        "getRequestsRepairReport",
                        {
                            region_id: currentRegionId,
                            date_beg: this.dateStart,
                            date_end: this.dateFinsh,
                        },
                    ));
                    const currentRepairReport = await this.getRepairReport3Items(requests);
                    repairReport = repairReport.concat(currentRepairReport);
                }
            }

            const outFileName = `Отчет по ремонтам №3 за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                items: repairReport,
            };

            createXls(
                this.siteHost,
                "repairreport-3.xlsx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveXls(repairreport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRepairReport3Items(requests) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                if (!this.allRegions) this.valueProgress = Math.round((i / requests.length) * 100);
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED &&
                    requests[i].view_res_before !== 3) { // исключить из отчёта заявки на списание
                    const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                    const regionId = this.getOrgProperty(orgId, "region_id");
                    const tool = await this.$store.dispatch(
                        "getSingleToolFromDB",
                        requests[i].tool_id,
                    );
                    const request_date = new Date(requests[i].request_date_add);
                    const co_8_date = requests[i].co_8_date
                        ? date.extractDate(requests[i].co_8_date, "YYYY-MM-DD") : 0;
                    this.counter++;
                    const item = {
                        num: this.counter,
                        region: regionId,
                        filial: this.getRegionName(regionId),
                        organization: this.getOrgProperty(orgId, "name"),
                        tool_kind: tool.set_id ? this.toolsSetName(tool.set_id) : "",
                        tool_kind_arm: this.getToolKindName(tool.tool_kind_id),
                        model: `${this.getManufacturerName(tool.manufacturer_id)} ${this.getToolModelName(tool.model_id)}`,
                        serial_number: tool.serial_number,
                        inventar_number: tool.inventar_number,
                        years: tool.work_in_dt
                            ? new Date(new Date() - new Date(tool.work_in_dt)).getFullYear() - 1970
                            : "",
                        is_subordinate: tool.owner_type_id === TOOLS_OWNERS_TYPES_VALUES.HOST ? "Да" : "",
                        is_fgbu: tool.owner_type_id === TOOLS_OWNERS_TYPES_VALUES.BASE ? "Да" : "",
                        is_other: "",
                        failure_cause: requests[i].troubles_list,
                        without_money: requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_HAND ? "Да" : "",
                        zip_rub: requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_SPARE ? requests[i].cost_spares || "" : "",
                        contract_rub: requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT ? requests[i].cost_work || "" : "",
                        is_warranty: requests[i].repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY ? "Да" : "",
                        remark: "",
                        num_gl: requests[i].hotlinegas_number,
                        act_co8: requests[i].co_8_number,
                        repair_days: requests[i].co_8_date
                            ? Math.trunc((co_8_date.getTime() - request_date.getTime()) / (1000 * 3600 * 24)) + 1 : "",
                        quarter: requests[i].co_8_date
                            ? Math.floor((co_8_date.getMonth() + 3) / 3) : "",
                    };
                    items.push(item);
                }
            }
            return items;
        },
    },
};
</script>
