<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета о количестве единиц ПТС на ремонт
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId && !allRegions"
                    style="width: 80%; display: inline-flex"
                    :disable="allRegions"
                />
                <q-checkbox
                    v-model="allRegions"
                    label="Все регионы"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import { getDataForReportTollsWaitingRepair } from "@/helpers/getDataForReports";
import { dateDMYtoYMD } from "@/helpers/helpers";

export default {
    name: "DialogCreateReportWaitingRepair",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            allRegions: false,
            dateStart: date.formatDate(date.startOfDate(new Date(), "year"), "DD.MM.YYYY"),
            dateFinsh: date.formatDate(date.endOfDate(new Date(), "year"), "DD.MM.YYYY"),
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            postTitle: "postTitle",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetName: "toolsSetName",
            getManufacturerName: "getManufacturerName",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    watch: {
        allRegions() {
            if (this.allRegions) {
                this.regionId = null;
            }
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refRegionId.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                (this.$refs.refRegionId.hasError && !this.allRegions)) {
                return;
            }

            if (this.allRegions) {
                this.showSpinner = true;
                this.valueProgress = 0;
            }

            const reportItems = [];

            for (let i = 0; i < this.regions.length; i++) {
                if (this.allRegions) this.valueProgress = Math.round((i / this.regions.length) * 100);
                const currentRegionId = this.regions[i].id;
                if ((this.allRegions && currentRegionId < 90) || (!this.allRegions && currentRegionId === this.regionId)) {
                    const reportData = await getDataForReportTollsWaitingRepair(
                        currentRegionId,
                        dateDMYtoYMD(this.dateStart),
                        dateDMYtoYMD(this.dateFinsh),
                    );
                    const item = {
                        region_name: this.getRegionName(currentRegionId),
                        tServers: reportData.tServers,
                        tSysBlocksMonitors: reportData.tSysBlocksMonitors,
                        tTerminalsVks: reportData.tTerminalsVks,
                        tInfoBooth: reportData.tInfoBooth,
                        tAvfs: reportData.tAvfs,
                        tAps: reportData.tAps,
                        tServersIvk: reportData.tServersIvk,
                        tAudiosVideosFix: reportData.tAudiosVideosFix,
                        tAudiosFix: reportData.tAudiosFix,
                        tComms: reportData.tComms,
                        tPrinters: reportData.tPrinters,
                        tMfus: reportData.tMfus,
                        tKmas: reportData.tKmas,
                        tWitness: reportData.tWitness,
                        tOthers: reportData.tOthers,
                        all:
                            +reportData.tServers +
                            +reportData.tSysBlocksMonitors +
                            +reportData.tTerminalsVks +
                            +reportData.tInfoBooth +
                            +reportData.tAvfs +
                            +reportData.tAps +
                            +reportData.tServersIvk +
                            +reportData.tAudiosVideosFix +
                            +reportData.tAudiosFix +
                            +reportData.tComms +
                            +reportData.tPrinters +
                            +reportData.tMfus +
                            +reportData.tKmas +
                            +reportData.tWitness +
                            +reportData.tOthers,
                    };
                    reportItems.push(item);
                }
            }

            const outFileName = `Отчет о количестве ПТС на ремонт за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                items: reportItems,
            };

            createXls(
                this.siteHost,
                "report-4-tools-waiting-repair.xlsx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveXls(repairreport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
