<template>
    <q-dialog
        ref="dialogEditToolSoftware"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Местоположение
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <PoeDatePicker
                    v-model="item.date_time"
                    labelTitle="Дата"
                    :futureDateAllowed="false"
                />
                <SelectFilter
                    ref="refUnit"
                    v-model="item.unit_id"
                    :options="unitsFull"
                    label="Объект"
                    clearable
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    extendedScope
                    class="q-mb-sm"
                />
                <q-input
                    v-model="item.room"
                    outlined
                    dense
                    class="q-mb-sm"
                    label="Помещение"
                />
                <q-input
                    v-model="item.man_post"
                    outlined
                    dense
                    class="q-mb-sm"
                    label="Должность"
                />
                <q-input
                    v-model="item.man_fio"
                    outlined
                    dense
                    class="q-mb-sm"
                    label="ФИО"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { dateYMDtoDMY, dateDMYtoYMD } from "@/helpers/helpers";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "DialogEditToolMovingDialogEdit",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                date_time: "",
                unit_id: null,
                room: "",
                map_post: "",
                man_fio: "",
            }),
        },
    },
    emits: ["ok"],
    data() {
        return {
            item: {},
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
        }),
    },
    methods: {
        show() {
            this.item = {
                ...this.itemForEdit,
                date_time: dateYMDtoDMY(this.itemForEdit.date_time),
            };
            this.$refs.dialogEditToolSoftware.show();
        },
        hide() {
            this.$refs.dialogEditToolSoftware.hide();
        },
        onOkClick() {
            this.$emit(
                "ok",
                {
                    ...this.item,
                    date_time: dateDMYtoYMD(this.item.date_time),
                },
            );

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
